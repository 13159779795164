import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { Component, OnInit, ViewChild, ViewEncapsulation, HostListener } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
declare var $: any;
import { ExcelService } from '../service/excel.service';
import { ReviewPaymentService } from './review-payment.service';
import { CommonService } from "../service/common.service";
import { UnenrollService } from '../unenroll-assosiate/unenroll.service';
import { NotificationService } from '../notification/notification.service'
import * as _ from "lodash";
import { AppComponent } from '../app.component';
import { LoginService } from '../login/login.service';
import { Router } from '@angular/router';

export interface Approval {
  status: number;
  dealercode: string;
  associatename: string;
  spinid: string;
  paymentnumber: string;
  paymenttime: string;
  Comments: string;
  check: boolean;
  ISCHECKED: number;
  Status: number;
  comments: string;
  toBeSaved: boolean;
  LFSPAYCD: string;
}

export interface Hold {
  status: string;
  DEALERNO: string;
  NAME: string;
  UID: string;
  TRANDATE: string;
  XREASON: string;
  AS_OF_Date: string;
  Comments: string;
  check: boolean;
  START_PERIOD: string;
  END_PERIOD: string;
  Status: string;
  paymentnumber: string;
  ISCHECKED: number;
  toBeSaved: boolean;
  LFSPAYCD: string;
}

export interface Exception {
  status: any;
  dealercode: string;
  associatename: string;
  spinid: string;
  paymentnumber: string;
  LFSPAYCD: string;
  paymenttime: string;
  Comments: string;
  check: boolean;
  comments: string;
  Status: any;
  ISCHECKED: number;
  toBeSaved: boolean;
}

@Component({
  selector: 'app-review-payment',
  templateUrl: './review-payment.component.html',
  styleUrls: ['./review-payment.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ReviewPaymentComponent implements OnInit {

  // BreadCrumbs Variable
  paymentStatus: string = "Payments Approval";

  //Tab Variable
  selectedTab: number = 1;
  tableSec: boolean = true;

  //Mat-Table Variables
  paymentApproval: boolean = true;
  paymentHold: boolean = false;
  paymentException: boolean = false;

  @ViewChild('approvalTablesort', { read: MatSort }) approvalTablesort: MatSort;
  @ViewChild('approvalTablepaginator', { read: MatPaginator }) approvalTablepaginator: MatPaginator;

  @ViewChild('holdTablesort', { read: MatSort }) holdTablesort: MatSort;
  @ViewChild('Holdpaginator', { read: MatPaginator }) Holdpaginator: MatPaginator;

  @ViewChild('exceptionTablesort', { read: MatSort }) exceptionTablesort: MatSort;
  @ViewChild('Exceptionpaginator', { read: MatPaginator }) Exceptionpaginator: MatPaginator;

  approvalDataColumns: string[] = ['Checkbox', 'status', 'dealercode', 'associatename', 'spinid', 'LFSPAYCD', 'paymenttime', 'Comments'];
  approvaldataSource: MatTableDataSource<any>;

  holdDataColumns: string[] = ['Checkbox', 'status', 'DEALERNO', 'NAME', 'SPIN_ID', 'LFSPAYCD', 'paymenttime', 'Comments'];
  holdDataSource: MatTableDataSource<any>;

  exceptionDataColumns: string[] = ['Checkbox', 'status', 'dealercode', 'associatename', 'spinid', 'LFSPAYCD', 'paymenttime', 'Comments'];
  exceptionDataSource: MatTableDataSource<any>;

  //statusFilter Variable
  errorData: any = [];
  acknowledgedData: any = [];
  postedData: any = [];
  awaitingData: any = [];

  errorDataHold: any = [];
  acknowledgedDataHold: any = [];
  postedDataHold: any = [];
  awaitingDataHold: any = [];


  errorDataException: any = [];
  acknowledgedDataException: any = [];
  postedDataException: any = [];
  awaitingDataException: any = [];

  //Excel Export Variable
  exportApproval: boolean = true;
  exportHold: boolean = false;
  exportException: boolean = false;
  exportApproveTab: boolean = false;

  //Variables
  checkedAll: boolean = false;
  totalChecked: number = 0;
  totalCheckedSaved: number = 0;
  totalCost: number;
  selectedRow = [];
  selection = [];
  noTableData: boolean = false;
  tableDataLoading: boolean = false;
  statusFilterboolen: boolean = false;
  statusFilterboolenHold: boolean = false;
  statusFilterboolenException: boolean = false;
  selectedDealer: boolean = false;
  selectedDealerName: string;
  exceptionSelectedDealer: boolean = false;
  exceptionSelectedDealerName: string;
  manualPayments: boolean = false;
  selectedDealerDetails: any[];

  // createManual Variables
  usersForm: FormGroup;
  addPaymentList: any = [];
  months: any = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  monthsHold: any = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  years: any = [];
  d = new Date();
  presentYr: any;
  presentMon: any;
  newPay: any = '01';
  countNumber: any;
  addMon: any;
  addYr: any;
  add: number = 0;
  monthsPayment: string = '';
  manualPayerName: string;
  successMsg: string;
  paid: boolean = false;
  manualPayUser: any;
  exceptionPush: any = [];
  spinIdClicked: any;
  spinId: any = '';
  isLoading: boolean;
  filteredUsers: any[] = [];
  manualPaymentSection: boolean = false;
  manualPayUserDetails: any = [];
  overflow: boolean = false;
  exceptionOverflow: boolean = false;
  holdOverflow: boolean = false;
  checkedRow: any = [];
  paymentDataResponse: any = [];
  holdPayDataResponse: any = [];
  exceptionDataResponse: any = [];
  ASSOCIATE_DATA: Approval[] = [];
  APPROVAL_DATA: Approval[] = [];
  APPROVAL_DATA_Unchanged: Approval[] = [];
  approvaltableLoading: boolean = false;
  exceptiontableLoading: boolean = false;
  EXCEPTION_DATA: Exception[] = [];
  excelException: any[] = [];
  excelHold: any[] = [];
  excelApproval: any[] = [];
  payment_list: any = [];
  holdtableLoading: boolean = false;
  HOLD_DATA: any[] = [];
  checkChrome: boolean;
  dasboardTab: boolean = false;
  pcDetails: any = [];
  dealercodeCheckResponse: any = [];

  approvalCheckCount = 0;
  holdCheckCount = 0;
  ExceptionCheckCount = 0;
  overrideHoldButton = 'OVERRIDE HOLD';
  overrideHoldClicked = false;

  panelOpenState = false;

  checkedArray = [];
  checkedArrayHold = [];
  checkedArrayException = [];

  selectOverRideCheck: any;
  totalCheckedArrayforApproval = [];
  dealercodeArray: any = [];
  dealerbodyArray: any = [];
  transferedDealercodeArray: any = [];
  transferAlert: boolean = false;
  dateMismatchArray = [];
  totalCheckedArrayforHold: any = [];
  selectedHoldIndex = 0;
  manualPaymentUser: any = [];
  showNoDataPaymentHistory = false;
  showNoDataPaymentList = false;

  dataForOverrideHold = [];
  checkAllApprovalV = false;
  checkAllHoldV = false;
  checkAllExceptionV = false;
  showSpinner = false;

  isActiveProceed = false;
  isActiveLeaseProceed = false;
  servicemethodIteration = 0;
  userCheck = true;
  leaseCheck = true;
  dcCheck = true;
  paymentCheck = true;
  paymentHistoryCheck = true;
  check24 = true;
  check12 = true;
  maximumCheck = true;
  jobcodeCheck: boolean = true;

  successLease = false;
  warnLease = false;
  successdc = false;
  warndc = false;
  successUser = false;
  warnUser = false;
  successPayment = '';
  warnPayment = '';
  successPaymentHistory = '';
  warnPaymentHistory = '';
  warn24 = '';
  success24 = '';
  warn12 = '';
  success12 = '';
  successMaximum = false;
  warnMaximum = false;
  successJobCode = false;
  warnJobCode = false;
  LFSErrorMsg = '';
  warnLFS = false;
  LFSFirstErrorMsg = '';
  warnFirstLFS = false;
  noHist: boolean = false;
  noHistBtnDis: boolean = false;

  disabledManualPayment = true;
  validatingManualPay;

  frozenDisabled = 0;
  remarksTouched = false;
  timeTouched = false;
  payNoTouched = false;

  userIsActiveMessage = '';
  leaseIsActiveMessage = '';
  dcMesage = '';
  maxMessage = '';
  duplicatePaymentMessage = '';
  duplicatePaymentHistoryMessage = '';
  message24 = '';
  message12 = '';
  autoEnrollErrorMsg = '';
  errorMsg = '';
  errorInManualPay = false;
  freezeMonth = '';
  jobCodeMessage = '';

  holdRecordCount = 0;
  approvalRecordCount = 0;
  exceptionRecordCount = 0;

  LFSPayCodeRsponse: any = '';
  firstLFSPayCodeResponse: any = '';
  closeAutoSearch: boolean;

  manualPayflagUpdateResponse: any = '';
  acCount = 0;
  bcCount = 0;
  enteredPayNo: number;

  constructor(private excelService: ExcelService, private unenrollService: UnenrollService, private fb: FormBuilder,
    private reviewService: ReviewPaymentService, public appService: CommonService, private notificationService: NotificationService,
    public appComponent: AppComponent, private loginService: LoginService, private router: Router) {
    if (this.appService.baseUrl != '') {
      // this.isfrozen();
      this.Exception();
      this.PaymentsApproval();
      this.PaymentsOnHold();
    }

    this.checkedRow = this.appService.getCheckedRows();
    for (let i = 1; i < this.checkedRow.length; i++) {
      if (this.checkedRow.indexOf(i) !== -1) {
        $('input[name="paymentCheckbox"]').attr('checked', true);
      }
    }
  }

  ngOnInit() {
    console.log('clg admin review pay', this.appService.role);
    if (this.appService.role != 'Lexus College' && this.appService.role != 'Lexus Associate' && this.appService.role != 'Area Admin') {
      this.appService.unauthorizedErrorMsg = "Sorry, you are not authorized to view this page. To request access, please contact Lexus Support at pcsupport@jacksondawson.com / LexusMasterLeaseSupport@lexus.com";
      this.router.navigate(['unauthorized']);
    }
    this.usersForm = this.fb.group({
      userInput: null
    });
    if (!!window['chrome']) {
      this.checkChrome = true;
    }
    else {
      this.checkChrome = false;
    }
    let currentYear = this.d.getFullYear();
    for (let i = 0; i < 30; i++) {
      this.years.push(currentYear);
      currentYear--;
    }
  }

  fadeDiv() {
    $('#manualPay').fadeTo('slow', .4);
    this.spinIdClicked = true;
  }

  fadeOut() {
    $('#manualPay').fadeTo('slow', 1);
    this.spinIdClicked = false;
  }

  //Select Tab
  select(index: number) {
    this.selectedTab = index;
    if (index == 1) {
      this.exportApproval = true;
      this.exportHold = false;
      this.exportException = false;
      this.paymentStatus = 'Payments Approval';
      this.paymentApproval = true;
      this.paymentHold = false;
      this.paymentException = false;
      this.manualPayments = false;
      this.tableSec = true;
      this.paid = false;

    } else if (index == 2) {
      this.exportHold = true;
      this.exportApproval = false;
      this.exportException = false;
      this.paymentStatus = 'Payments On Hold';
      this.paymentHold = true;
      this.paymentApproval = false;
      this.paymentException = false;
      this.manualPayments = false;
      this.tableSec = true;
      this.paid = false;
    } else if (index == 3) {
      this.exportException = true;
      this.exportApproval = false;
      this.exportHold = false;
      this.paymentStatus = 'Program Exceptions';
      this.paymentException = true;
      this.paymentApproval = false;
      this.paymentHold = false;
      this.manualPayments = false;
      this.tableSec = true;
      this.paid = false;
    } else {
      this.paymentStatus = 'Add Manual Payment';
      this.paymentApproval = false;
      this.paymentHold = false;
      this.paymentException = true;
      this.manualPayments = true;
      this.tableSec = true;
      this.paid = false;
      $('html,body').animate({ scrollBottom: document.body.scrollHeight }, "fast");
    }
  }

  isfrozen() {
    this.reviewService.isFrozen().subscribe((response: any) => {
      this.frozenDisabled = response.StatusDetail.StatusDetail[0].ISACTIVE;
    });
  }

  PaymentsApproval() {
    this.approvaltableLoading = true;
    this.APPROVAL_DATA = [];
    this.approvaldataSource = new MatTableDataSource(this.APPROVAL_DATA);
    this.reviewService.getPaymentRecord('1', this.appService.selectedRegion).subscribe((response: any) => {
      this.paymentDataResponse = response.PaymentDetail.PaymentDetail;
      [].push.apply(this.APPROVAL_DATA, this.paymentDataResponse);
      for (let i = 0; i < this.APPROVAL_DATA.length; i++) {
        if (this.APPROVAL_DATA[i].comments == null || this.APPROVAL_DATA[i].comments.toLowerCase() == "null") {
          this.APPROVAL_DATA[i].Comments = '';
        } else {
          this.APPROVAL_DATA[i].Comments = this.APPROVAL_DATA[i].comments;
        }
        if (this.APPROVAL_DATA[i].ISCHECKED == 1 && this.APPROVAL_DATA[i].Status == 3) {
          // Please check this logic (now changed to false because of error)
          this.APPROVAL_DATA[i].check = true;
        }
        else {
          this.APPROVAL_DATA[i].check = false;
        }
        this.APPROVAL_DATA[i].toBeSaved = false;
        if (this.APPROVAL_DATA[i].Status == 3) {
          this.awaitingData.push(this.APPROVAL_DATA[i]);
        }
        else if (this.APPROVAL_DATA[i].Status == 4) {
          this.postedData.push(this.APPROVAL_DATA[i]);
        }
        else if (this.APPROVAL_DATA[i].Status == 5) {
          this.APPROVAL_DATA[i].Status = 2;
          this.acknowledgedData.push(this.APPROVAL_DATA[i]);
        }
        else if (this.APPROVAL_DATA[i].Status == 6) {
          this.APPROVAL_DATA[i].Status = 1;
          this.errorData.push(this.APPROVAL_DATA[i]);
        }
        this.APPROVAL_DATA_Unchanged = this.APPROVAL_DATA;
      }
      this.approvaldataSource = new MatTableDataSource(this.APPROVAL_DATA);
      this.approvaldataSource.sort = this.approvalTablesort;
      this.approvaldataSource.paginator = this.approvalTablepaginator;
      if (this.approvaldataSource.data.length > 1) {
        this.approvalRecordCount = this.approvaldataSource.data.length;
        this.overflow = true;
      } else {
        this.overflow = false;
      }
      this.getAllPaymentFn();
      this.approvaltableLoading = false;
    });
  }

  PaymentsOnHold() {
    this.holdtableLoading = true;
    this.holdPayDataResponse = [];
    this.HOLD_DATA = [];
    this.holdDataSource = new MatTableDataSource(this.HOLD_DATA);
    this.reviewService.getPaymentRecord('2', this.appService.selectedRegion).subscribe((response: any) => {
      this.holdPayDataResponse = response.PaymentDetail.PaymentDetail;
      [].push.apply(this.HOLD_DATA, this.holdPayDataResponse);
      for (let i = 0; i < this.HOLD_DATA.length; i++) {
        this.HOLD_DATA[i].check = false;
        this.HOLD_DATA[i].startPeriodForapproval = this.HOLD_DATA[i].START_PERIOD;
        this.HOLD_DATA[i].endPeriodForapproval = this.HOLD_DATA[i].END_PERIOD;
        if (this.HOLD_DATA[i].ISCHECKED == 1) {
          this.HOLD_DATA[i].check = true;
        }
        this.HOLD_DATA[i].DEALER_CODE = this.HOLD_DATA[i].dealercode;
        this.HOLD_DATA[i].SPIN_ID = this.HOLD_DATA[i].spinid;
        this.HOLD_DATA[i].NAME = this.HOLD_DATA[i].associatename;
        console.log('month', this.HOLD_DATA[i].START_PERIOD.substring(5, 7));
        const month = parseInt(this.HOLD_DATA[i].START_PERIOD.substring(5, 7));
        const endMonth = parseInt(this.HOLD_DATA[i].END_PERIOD.substring(5, 7));
        var endYear = '';
        this.HOLD_DATA[i].START_PERIOD = this.monthsHold[month - 1] + ' ' + this.HOLD_DATA[i].START_PERIOD.substring(0, 4);
        if (this.HOLD_DATA[i].END_PERIOD.substring(0, 4) == '2999') {
          endYear = 'N.A';
          this.HOLD_DATA[i].Comments = 'Payment Approved from \n' +
            this.HOLD_DATA[i].START_PERIOD;
          this.HOLD_DATA[i].END_PERIOD = endYear;
        }
        else {
          endYear = this.HOLD_DATA[i].END_PERIOD.substring(0, 4);
          this.HOLD_DATA[i].END_PERIOD = this.monthsHold[endMonth - 1] + ' ' + endYear;
          this.HOLD_DATA[i].Comments = 'Payment Approved between \n' +
            this.HOLD_DATA[i].START_PERIOD + ' and ' + this.HOLD_DATA[i].END_PERIOD;
        }
        this.HOLD_DATA[i].comments = this.HOLD_DATA[i].Comments;
        if (this.HOLD_DATA[i].Status == 8) {
          this.HOLD_DATA[i].Status = 3;
          this.awaitingDataHold.push(this.HOLD_DATA[i]);
        }
        else if (this.HOLD_DATA[i].Status == 13) {
          this.HOLD_DATA[i].Status = 4;
          this.postedDataHold.push(this.HOLD_DATA[i]);
        }
        else if (this.HOLD_DATA[i].Status == 14) {
          this.HOLD_DATA[i].Status = 2;
          this.acknowledgedDataHold.push(this.HOLD_DATA[i]);
        }
        else if (this.HOLD_DATA[i].Status == 15) {
          this.HOLD_DATA[i].Status = 1;
          this.errorDataHold.push(this.HOLD_DATA[i]);
        }
        this.HOLD_DATA[i].toBeSaved = false;
      }
      this.holdDataSource = new MatTableDataSource(this.HOLD_DATA);
      this.holdDataSource.sort = this.holdTablesort;
      this.holdDataSource.paginator = this.Holdpaginator;
      if (this.holdDataSource.data.length > 10) {
        this.holdOverflow = true;
      } else {
        this.holdOverflow = false;
      }
      this.getAllPaymentFn();
      if (this.holdDataSource.data.length > 1) {
        this.holdRecordCount = this.holdDataSource.data.length;
      }
      this.holdtableLoading = false;
    });

  }

  Exception() {
    this.EXCEPTION_DATA = [];
    this.exceptionDataResponse = [];
    this.exceptionDataSource = new MatTableDataSource(this.EXCEPTION_DATA);
    this.exceptiontableLoading = true;
    this.reviewService.getPaymentRecord('3', this.appService.selectedRegion).subscribe((response: any) => {
      this.exceptionDataResponse = response.PaymentDetail.PaymentDetail;
      [].push.apply(this.EXCEPTION_DATA, this.exceptionDataResponse);
      for (let i = 0; i < this.EXCEPTION_DATA.length; i++) {
        if (this.EXCEPTION_DATA[i].comments == null || this.EXCEPTION_DATA[i].comments.toLowerCase() == "null") {
          this.EXCEPTION_DATA[i].Comments = '';
        }
        else {
          this.EXCEPTION_DATA[i].Comments = this.EXCEPTION_DATA[i].comments;
        }
        if (this.EXCEPTION_DATA[i].ISCHECKED == 1) {
          this.EXCEPTION_DATA[i].check = true;
        }
        else {
          this.EXCEPTION_DATA[i].check = false;
        }
        if (this.EXCEPTION_DATA[i].Status == 9 || this.EXCEPTION_DATA[i].Status == 1
          || this.EXCEPTION_DATA[i].Status == 7) {
          this.EXCEPTION_DATA[i].Status = 3;
          this.awaitingDataException.push(this.EXCEPTION_DATA[i]);
        }
        if (this.EXCEPTION_DATA[i].Status == 10) {
          this.EXCEPTION_DATA[i].Status = 4;
          this.postedDataException.push(this.EXCEPTION_DATA[i]);
        }
        if (this.EXCEPTION_DATA[i].Status == 11) {
          this.EXCEPTION_DATA[i].Status = 2;
          this.EXCEPTION_DATA[i].check = false;
          this.acknowledgedDataException.push(this.EXCEPTION_DATA[i]);
        }
        if (this.EXCEPTION_DATA[i].Status == 2) {
          this.EXCEPTION_DATA[i].Status = 1;
          this.EXCEPTION_DATA[i].check = false;
          this.errorDataException.push(this.EXCEPTION_DATA[i]);
        }
        if (this.EXCEPTION_DATA[i].Status == 12) {
          this.EXCEPTION_DATA[i].Status = 1;
          this.EXCEPTION_DATA[i].check = true;
          this.errorDataException.push(this.EXCEPTION_DATA[i]);
        }
        this.EXCEPTION_DATA[i].toBeSaved = false;
      }
      this.exceptionDataSource = new MatTableDataSource(this.EXCEPTION_DATA);
      this.exceptionDataSource.sort = this.exceptionTablesort;
      this.exceptionDataSource.paginator = this.Exceptionpaginator;
      if (this.exceptionDataSource.data.length > 10) {
        this.exceptionOverflow = true;
      } else {
        this.exceptionOverflow = false;
      }
      this.getAllPaymentFn();
      if (this.exceptionDataSource.data.length > 1) {
        this.exceptionRecordCount = this.exceptionDataSource.data.length;
      }
      this.exceptiontableLoading = false;
    });
  }

  //Filters
  approvalFilter(filterValue: string) {
    this.approvaldataSource.filter = filterValue.trim().toLowerCase();
    this.approvaldataSource.sort = this.approvalTablesort;
    this.approvaldataSource.paginator = this.approvalTablepaginator;
  }

  holdFilter(filterValue: string) {
    this.holdDataSource.filter = filterValue.trim().toLowerCase();
    this.holdDataSource.sort = this.holdTablesort;
    this.holdDataSource.paginator = this.Holdpaginator;
  }

  exceptionFilter(filterValue: string) {
    this.exceptionDataSource.filter = filterValue.trim().toLowerCase();
    this.exceptionDataSource.sort = this.exceptionTablesort;
    this.exceptionDataSource.paginator = this.Exceptionpaginator;
  }

  //Status Filter
  awaitingApproval() {
    this.statusFilterboolen = false;
    this.approvaldataSource = new MatTableDataSource(this.awaitingData);
    this.approvaldataSource.sort = this.approvalTablesort;
    this.approvaldataSource.paginator = this.approvalTablepaginator;
  }

  postedToPeople() {
    this.statusFilterboolen = false;
    this.approvaldataSource = new MatTableDataSource(this.postedData);
    this.approvaldataSource.sort = this.approvalTablesort;
    this.approvaldataSource.paginator = this.approvalTablepaginator;
  }

  Acknowledged() {
    this.statusFilterboolen = false;
    this.approvaldataSource = new MatTableDataSource(this.acknowledgedData);
    this.approvaldataSource.sort = this.approvalTablesort;
    this.approvaldataSource.paginator = this.approvalTablepaginator;
  }

  errorProccessing() {
    this.statusFilterboolen = false;
    this.approvaldataSource = new MatTableDataSource(this.errorData);
    this.approvaldataSource.sort = this.approvalTablesort;
    this.approvaldataSource.paginator = this.approvalTablepaginator;
  }

  awaitingApprovalHold() {
    this.statusFilterboolenHold = false;
    this.holdDataSource = new MatTableDataSource(this.awaitingDataHold);
    this.holdDataSource.sort = this.holdTablesort;
    this.holdDataSource.paginator = this.Holdpaginator;
  }

  postedToPeopleHold() {
    this.statusFilterboolenHold = false;
    this.holdDataSource = new MatTableDataSource(this.postedDataHold);
    this.holdDataSource.sort = this.holdTablesort;
    this.holdDataSource.paginator = this.Holdpaginator;
  }

  AcknowledgedHold() {
    this.statusFilterboolenHold = false;
    this.holdDataSource = new MatTableDataSource(this.acknowledgedDataHold);
    this.holdDataSource.sort = this.holdTablesort;
    this.holdDataSource.paginator = this.Holdpaginator;
  }

  errorProccessingHold() {
    this.statusFilterboolenHold = false;
    this.holdDataSource = new MatTableDataSource(this.errorDataHold);
    this.holdDataSource.sort = this.holdTablesort;
    this.holdDataSource.paginator = this.Holdpaginator;
  }

  awaitingApprovalException() {
    this.statusFilterboolenException = false;
    this.exceptionDataSource = new MatTableDataSource(this.awaitingDataException);
    this.exceptionDataSource.sort = this.exceptionTablesort;
    this.exceptionDataSource.paginator = this.Exceptionpaginator;
  }

  postedToPeopleException() {
    this.statusFilterboolenException = false;
    this.exceptionDataSource = new MatTableDataSource(this.postedDataException);
    this.exceptionDataSource.sort = this.exceptionTablesort;
    this.exceptionDataSource.paginator = this.Exceptionpaginator;
  }

  AcknowledgedException() {
    this.statusFilterboolenException = false;
    this.exceptionDataSource = new MatTableDataSource(this.acknowledgedDataException);
    this.exceptionDataSource.sort = this.exceptionTablesort;
    this.exceptionDataSource.paginator = this.Exceptionpaginator;
  }

  errorProccessingException() {
    this.statusFilterboolenException = false;
    this.exceptionDataSource = new MatTableDataSource(this.errorDataException);
    this.exceptionDataSource.sort = this.exceptionTablesort;
    this.exceptionDataSource.paginator = this.Exceptionpaginator;
  }

  // CheckBox Count in Approval Table
  getCheckedCount(index, row) {
    if (row.Status != 4) {
      this.checkedArray.push(row);
      this.APPROVAL_DATA.forEach(function (approvalData) {
        if (approvalData.paymentnumber == row.paymentnumber && approvalData.spinid == row.spinid && approvalData.paymenttime == row.paymenttime) {
          approvalData.check = row.check;
          approvalData.toBeSaved = !approvalData.toBeSaved;
        }
      });
      // this.APPROVAL_DATA[index].check = row.check;
      // this.APPROVAL_DATA[index].toBeSaved = !this.APPROVAL_DATA[index].toBeSaved;
      this.getAllPaymentFn();
      this.totalCheckedSaved = $(":checkbox:checked").length;
      this.totalCost = this.totalChecked * 200;
      // console.log('Index', index);
      if (this.selection.indexOf(row.associatename) !== -1) {
        this.selection.splice(this.selection.indexOf(row.associatename), 1);
      } else {
        this.selection.push(row.associatename);
      }
    }
  }

  getAllChecked(key) {
    if (key == 'all') {
      this.approvalCheckCount = this.approvaldataSource.data.length;
      this.totalChecked = 0;
      this.totalChecked = this.holdCheckCount + this.approvalCheckCount + this.ExceptionCheckCount;
      this.totalCheckedSaved = this.totalChecked;
      this.totalCost = this.totalChecked * 200;
      $("#approveModalId").attr('data-badge', this.totalChecked + '');
      for (let i = 0; i < this.approvaldataSource.data.length; i++) {
        this.selection.push(this.approvaldataSource.data[i].associatename);
      }
    } else if (key == 'none') {
      this.approvalCheckCount = 0;
      this.totalChecked = 0;
      this.totalChecked = this.holdCheckCount + this.approvalCheckCount + this.ExceptionCheckCount;
      this.totalCheckedSaved = 0;
      this.totalCost = 0;
      this.selection = [];
      $("#approveModalId").removeAttr('data-badge');
    }
  }

  checkAll() {
    if (!this.checkAllApprovalV) {
      this.checkAllApprovalV = true;
      this.approvalCheckCount = 0;
      console.log('Search ', $('#approveSearch').val());
      console.log('check All Approve data', this.approvaldataSource.filteredData)
      if ($('#approveSearch').val() != '') {
        for (let i = 0; i < this.approvaldataSource.filteredData.length; i++) {
          if (this.approvaldataSource.filteredData[i].Status == 3) {
            this.approvaldataSource.filteredData[i].check = true;
            this.approvaldataSource.filteredData[i].toBeSaved = true;
            this.approvalCheckCount += 1;
          }
        }
      } else {
        for (let i = 0; i < this.approvaldataSource.data.length; i++) {
          if (this.approvaldataSource.data[i].Status == 3) {
            this.approvaldataSource.data[i].check = true;
            this.approvaldataSource.data[i].toBeSaved = true;
            this.approvalCheckCount += 1;
          }
        }
      }

      this.totalChecked = 0;
      this.totalChecked = this.holdCheckCount + this.approvalCheckCount + this.ExceptionCheckCount;
      if (this.totalChecked != 0) {
        this.appService.unsavedChanges = true;
        $("#approveModalId").attr('data-badge', this.totalChecked + '');
      } else {
        this.appService.unsavedChanges = false;
        $("#approveModalId").removeAttr('data-badge');
      }
    }
    else {
      this.checkAllApprovalV = false;
      this.approvalCheckCount = 0;
      for (let i = 0; i < this.approvaldataSource.data.length; i++) {
        if (this.approvaldataSource.data[i].Status == 3) {
          this.approvaldataSource.data[i].check = false;
          this.approvaldataSource.data[i].toBeSaved = true;
          // this.approvalCheckCount -= 1;
        }
      }
      this.totalChecked = 0;
      this.totalChecked = this.holdCheckCount + this.approvalCheckCount + this.ExceptionCheckCount;
      if (this.totalChecked != 0) {
        this.appService.unsavedChanges = true;
        $("#approveModalId").attr('data-badge', this.totalChecked + '');
      } else {
        this.appService.unsavedChanges = false;
        $("#approveModalId").removeAttr('data-badge');
      }
    }
    this.getAllPaymentFn();
  }


  // CheckBox Count in Hold Table
  getCheckedCountHold(index, row) {
    this.HOLD_DATA.forEach(function (holdData) {
      if (holdData.paymentnumber == row.paymentnumber && holdData.spinid == row.spinid && holdData.paymenttime == row.paymenttime) {
        holdData.check = row.check;
        holdData.toBeSaved = !holdData.toBeSaved;
      }
    });
    // this.HOLD_DATA[index].check = row.check;
    // this.HOLD_DATA[index].toBeSaved = !this.HOLD_DATA[index].toBeSaved;
    this.selectedHoldIndex = index;
    this.getAllPaymentFn();
    this.totalCheckedSaved = $(":checkbox:checked").length;
    this.totalCost = this.totalChecked * 200;
    // console.log('Index', index);
    if (this.selection.indexOf(row.associatename) !== -1) {
      this.selection.splice(this.selection.indexOf(row.associatename), 1);
    } else {
      this.selection.push(row.associatename);
    }
  }

  getAllCheckedHold(key) {
    if (!this.checkAllHoldV) {
      this.checkAllHoldV = true;
      this.holdCheckCount = 0;
      for (let i = 0; i < this.holdDataSource.data.length; i++) {
        if (this.holdDataSource.data[i].Status == 3) {
          this.holdDataSource.data[i].check = true;
          this.holdDataSource.data[i].toBeSaved = true;
          this.holdCheckCount += 1;
        }
      }
      this.totalChecked = 0;
      this.totalChecked = this.holdCheckCount + this.approvalCheckCount + this.ExceptionCheckCount;
      if (this.totalChecked != 0) {
        this.appService.unsavedChanges = true;
        $("#approveModalId").attr('data-badge', this.totalChecked + '');
      } else {
        this.appService.unsavedChanges = false;
        $("#approveModalId").removeAttr('data-badge');
      }
    }
    else {
      this.checkAllHoldV = false;
      this.holdCheckCount = 0;
      for (let i = 0; i < this.holdDataSource.data.length; i++) {
        if (this.holdDataSource.data[i].Status == 3) {
          this.holdDataSource.data[i].check = false;
          this.holdDataSource.data[i].toBeSaved = false;
          // this.approvalCheckCount -= 1;
        }
      }
      this.totalChecked = 0;
      this.totalChecked = this.holdCheckCount + this.approvalCheckCount + this.ExceptionCheckCount;
      if (this.totalChecked != 0) {
        this.appService.unsavedChanges = true;
        $("#approveModalId").attr('data-badge', this.totalChecked + '');
      } else {
        this.appService.unsavedChanges = false;
        $("#approveModalId").removeAttr('data-badge');
      }
    }
  }

  checkAllHold() {
    if (!this.checkAllHoldV) {
      this.checkAllHoldV = true;
      this.holdCheckCount = 0;
      if ($('#HoldSearch').val() != '') {
        for (let i = 0; i < this.holdDataSource.filteredData.length; i++) {
          if (this.holdDataSource.filteredData[i].Status == 3) {
            this.holdDataSource.filteredData[i].check = true;
            this.holdDataSource.filteredData[i].toBeSaved = true;
            this.holdCheckCount += 1;
          }
        }
      } else {
        for (let i = 0; i < this.holdDataSource.data.length; i++) {
          if (this.holdDataSource.data[i].Status == 3) {
            this.holdDataSource.data[i].check = true;
            this.holdDataSource.data[i].toBeSaved = true;
            this.holdCheckCount += 1;
          }
        }
      }
      this.totalChecked = 0;
      this.totalChecked = this.holdCheckCount + this.approvalCheckCount + this.ExceptionCheckCount;
      if (this.totalChecked != 0) {
        this.appService.unsavedChanges = true;
        $("#approveModalId").attr('data-badge', this.totalChecked + '');
      } else {
        this.appService.unsavedChanges = false;
        $("#approveModalId").removeAttr('data-badge');
      }
    }
    else {
      this.checkAllHoldV = false;
      this.holdCheckCount = 0;
      for (let i = 0; i < this.holdDataSource.data.length; i++) {
        if (this.holdDataSource.data[i].Status == 3) {
          this.holdDataSource.data[i].check = false;
          this.holdDataSource.data[i].toBeSaved = true; //changed to true from false due to unable to save after uncheckthis btn on 12th Feb2020
          // this.approvalCheckCount -= 1;
        }
      }
      this.totalChecked = 0;
      this.totalChecked = this.holdCheckCount + this.approvalCheckCount + this.ExceptionCheckCount;
      if (this.totalChecked != 0) {
        this.appService.unsavedChanges = true;
        $("#approveModalId").attr('data-badge', this.totalChecked + '');
      } else {
        this.appService.unsavedChanges = false;
        $("#approveModalId").removeAttr('data-badge');
      }
    }
    this.getAllPaymentFn();
  }


  // CheckBox Count in Exception Table
  getCheckedCountException(index, row) {
    this.checkedArrayException.push(row);
    this.EXCEPTION_DATA.forEach(function (exceptionData) {
      if (exceptionData.paymentnumber == row.paymentnumber && exceptionData.spinid == row.spinid && exceptionData.paymenttime == row.paymenttime) {
        exceptionData.check = row.check;
        exceptionData.toBeSaved = !exceptionData.toBeSaved;
        console.log(exceptionData.associatename + '::::' + exceptionData.toBeSaved);
      }
    });
    for (let i = 0; i < this.EXCEPTION_DATA.length; i++) {
      if (this.EXCEPTION_DATA[i].check) {
        this.ExceptionCheckCount += 1;
      }
    }
    this.getAllPaymentFn();
    this.totalCheckedSaved = $(":checkbox:checked").length;
    this.totalCost = this.totalChecked * 200;
    if (this.selection.indexOf(row.associatename) !== -1) {
      this.selection.splice(this.selection.indexOf(row.associatename), 1);
    } else {
      this.selection.push(row.associatename);
    }
  }

  getAllCheckedException(key) {
    if (key == 'all') {
      this.ExceptionCheckCount = this.exceptionDataSource.data.length;
      this.totalChecked = 0;
      this.totalChecked = this.holdCheckCount + this.approvalCheckCount + this.ExceptionCheckCount;
      this.totalCheckedSaved = this.totalChecked;
      this.totalCost = this.totalChecked * 200;
      $("#approveModalId").attr('data-badge', this.totalChecked + '');
      for (let i = 0; i < this.approvaldataSource.data.length; i++) {
        this.selection.push(this.approvaldataSource.data[i].associatename);
      }
    } else if (key == 'none') {
      this.ExceptionCheckCount = 0;
      this.totalChecked = 0;
      this.totalChecked = this.holdCheckCount + this.approvalCheckCount + this.ExceptionCheckCount;
      this.totalCheckedSaved = 0;
      this.totalCost = 0;
      this.selection = [];
      $("#approveModalId").removeAttr('data-badge');
    }
  }

  checkAllException() {
    if (!this.checkAllExceptionV) {
      this.checkAllExceptionV = true;
      this.ExceptionCheckCount = 0;
      if ($('#ExceptionSearch').val() != '') {
        for (let i = 0; i < this.exceptionDataSource.filteredData.length; i++) {
          if (this.exceptionDataSource.data[i].Status == 3) {
            this.exceptionDataSource.filteredData[i].check = true;
            this.exceptionDataSource.filteredData[i].toBeSaved = true;
            this.ExceptionCheckCount += 1;
          }
        }
      } else {
        for (let i = 0; i < this.exceptionDataSource.data.length; i++) {
          if (this.exceptionDataSource.data[i].Status == 3) {
            this.exceptionDataSource.data[i].check = true;
            this.exceptionDataSource.data[i].toBeSaved = true;
            this.ExceptionCheckCount += 1;
          }
        }
      }
      this.totalChecked = 0;
      this.totalChecked = this.holdCheckCount + this.approvalCheckCount + this.ExceptionCheckCount;
      if (this.totalChecked != 0) {
        this.appService.unsavedChanges = true;
        $("#approveModalId").attr('data-badge', this.totalChecked + '');
      } else {
        this.appService.unsavedChanges = false;
        $("#approveModalId").removeAttr('data-badge');
      }
    }
    else {
      this.checkAllExceptionV = false;
      this.ExceptionCheckCount = 0;
      for (let i = 0; i < this.exceptionDataSource.data.length; i++) {
        if (this.exceptionDataSource.data[i].Status == 3) {
          this.exceptionDataSource.data[i].check = false;
          this.exceptionDataSource.data[i].toBeSaved = true; // changed from false to true on 12th Feb 2020
          // this.approvalCheckCount -= 1;
        }
      }
      this.totalChecked = 0;
      this.totalChecked = this.holdCheckCount + this.approvalCheckCount + this.ExceptionCheckCount;
      if (this.totalChecked != 0) {
        this.appService.unsavedChanges = true;
        $("#approveModalId").attr('data-badge', this.totalChecked + '');
      } else {
        this.appService.unsavedChanges = false;
        $("#approveModalId").removeAttr('data-badge');
      }
    }
  }

  getAllPaymentFn() {
    let j = 0;
    let k = 0;
    this.exportApproveTab = true;
    this.exportApproval = false;
    this.exportHold = false;
    this.exportException = false;
    this.transferAlert = false;
    this.totalCheckedArrayforApproval = [];
    this.totalCheckedArrayforHold = [];
    this.dealercodeArray = [];
    this.dealerbodyArray = [];
    this.transferedDealercodeArray = [];
    this.approvalCheckCount = 0;
    this.holdCheckCount = 0;
    this.ExceptionCheckCount = 0;
    for (let i = 0; i < this.APPROVAL_DATA.length; i++) {
      if (this.APPROVAL_DATA[i].check && this.APPROVAL_DATA[i].Status == 3) {
        this.totalCheckedArrayforApproval[j] = this.APPROVAL_DATA[i];
        this.totalCheckedArrayforApproval[j].DEALER_CODE = this.APPROVAL_DATA[i].dealercode;
        this.totalCheckedArrayforApproval[j].SPIN_ID = this.APPROVAL_DATA[i].spinid;
        this.totalCheckedArrayforApproval[j].paymentType = '1';
        this.totalCheckedArrayforApproval[j].comments = this.APPROVAL_DATA[i].Comments;
        this.dealercodeArray.push(this.APPROVAL_DATA[i].dealercode);
        j += 1;
        this.approvalCheckCount += 1;
      }
      if (this.APPROVAL_DATA[i].toBeSaved) {
        // this.approvalCheckCount += 1;
      }
    }
    for (let i = 0; i < this.HOLD_DATA.length; i++) {
      if (this.HOLD_DATA[i].check) {
        this.totalCheckedArrayforHold[k] = this.HOLD_DATA[i];
        this.totalCheckedArrayforHold[k].DEALER_CODE = this.HOLD_DATA[i].DEALER_CODE;
        this.totalCheckedArrayforHold[k].SPIN_ID = this.HOLD_DATA[i].SPIN_ID;
        this.totalCheckedArrayforHold[k].associatename = this.HOLD_DATA[i].NAME;
        this.totalCheckedArrayforHold[k].paymentnumber = this.HOLD_DATA[i].paymentnumber;
        this.totalCheckedArrayforHold[k].paymenttime = this.HOLD_DATA[i].paymenttime;
        this.totalCheckedArrayforHold[k].paymentType = '2';
        this.totalCheckedArrayforHold[k].Comments = this.HOLD_DATA[i].Comments;
        this.totalCheckedArrayforApproval[j] = this.HOLD_DATA[i];
        this.totalCheckedArrayforApproval[j].DEALER_CODE = this.HOLD_DATA[i].DEALER_CODE;
        this.totalCheckedArrayforApproval[j].SPIN_ID = this.HOLD_DATA[i].SPIN_ID;
        this.totalCheckedArrayforApproval[j].associatename = this.HOLD_DATA[i].NAME;
        this.totalCheckedArrayforApproval[j].paymentnumber = this.HOLD_DATA[i].paymentnumber;
        this.totalCheckedArrayforApproval[j].paymenttime = this.HOLD_DATA[i].paymenttime;
        this.totalCheckedArrayforApproval[j].paymentType = '2';
        this.totalCheckedArrayforApproval[j].comments = this.HOLD_DATA[i].Comments;
        this.dealercodeArray.push(this.HOLD_DATA[i].DEALER_CODE);
        k += 1;
        j += 1;
        this.holdCheckCount += 1;
      }
      if (this.HOLD_DATA[i].toBeSaved) {
        // this.holdCheckCount += 1;
      }
    }
    for (let i = 0; i < this.EXCEPTION_DATA.length; i++) {
      if (this.EXCEPTION_DATA[i].check) {
        this.totalCheckedArrayforApproval[j] = this.EXCEPTION_DATA[i];
        this.totalCheckedArrayforApproval[j].DEALER_CODE = this.EXCEPTION_DATA[i].dealercode;
        this.totalCheckedArrayforApproval[j].SPIN_ID = this.EXCEPTION_DATA[i].spinid;
        this.totalCheckedArrayforApproval[j].paymentType = '3';
        this.totalCheckedArrayforApproval[j].comments = this.EXCEPTION_DATA[i].Comments;
        this.dealercodeArray.push(this.EXCEPTION_DATA[i].dealercode);
        j += 1;
        this.ExceptionCheckCount += 1;
      }
      if (this.EXCEPTION_DATA[i].toBeSaved) {
        // this.ExceptionCheckCount += 1;
      }
    }

    this.totalChecked = this.totalCheckedArrayforApproval.length;
    this.acCount = 0; this.bcCount = 0;

    for (let i = 0; i < this.totalCheckedArrayforApproval.length; i++) {
      let z = 0;
      let compareMonth = '';
      if (this.d.getMonth() == 0) { //If the current month is Jan then d.getMonth() will be 0 to get the previous month d.getMonth()-1 logic is added. Inorder to avoid error I have added this logic
        compareMonth = '11';
      } else {
        compareMonth = (this.d.getMonth() - 1).toString();
      }

      if (this.monthsHold.indexOf(this.totalCheckedArrayforApproval[i].paymenttime.substring(0, 3).toUpperCase())
        == compareMonth) {
        this.dateMismatchArray[z] = this.totalCheckedArrayforApproval[i];
        z++;
      }
      if (new Date(this.totalCheckedArrayforApproval[i].paymenttime) >= new Date('OCT-2023')) { //has to be 'OCT-2023'
        this.totalCheckedArrayforApproval[i].newAmount = '$ 300';
        this.acCount++ //after change
      } else {
        this.totalCheckedArrayforApproval[i].newAmount = '$ 200';
        this.bcCount++ //before change
      }
    }
    console.log('totalChecked=', this.totalChecked + ' approval=', this.approvalCheckCount + ' hold=', this.holdCheckCount + ' Exception=', this.ExceptionCheckCount);
    if (this.totalChecked != 0) {
      $("#approveModalId").attr('data-badge', this.totalChecked + '');
    } else {
      $("#approveModalId").removeAttr('data-badge');
    }
    if (this.holdCheckCount > 0 || this.approvalCheckCount > 0 || this.ExceptionCheckCount > 0) {
      this.appService.unsavedChanges = true;
    }
    else {
      this.appService.unsavedChanges = false;
    }
  }

  async getPayhistoryCount() {
    this.showSpinner = true;
    this.dealerbodyArray = [];
    this.transferedDealercodeArray = [];
    var newPaymentNoArray = [];
    console.log('Total Checked Array for Approval ', this.totalCheckedArrayforApproval);
    this.dealercodeArray = this.dealercodeArray.filter(function (elem, index, self) { return index === self.indexOf(elem); })
    console.log('Unique Dealercode for Validation ', this.dealercodeArray);
    this.dealercodeArray = this.dealercodeArray.toString();
    await this.reviewService.dealercodeCheck(this.dealercodeArray).toPromise().then((response: any) => {
      this.dealercodeCheckResponse = response.CheckDealercodeValidation.Result;
      //console.log("Testing",this.dealercodeCheckResponse);
    });
    for (let i = 0; i < this.dealercodeCheckResponse.length; i++) {
      if (this.dealercodeCheckResponse[i].status != 'valid') {
        this.transferedDealercodeArray.push(this.dealercodeCheckResponse[i].dlr);
      }
    }
    // console.log(this.transferedDealercodeArray);
    if (this.transferedDealercodeArray.length != 0) {
      this.transferAlert = true;
      console.log('Terminated Dealercodes ', this.transferedDealercodeArray);
    }

    // =================== Commented Dynamic LFS Pay Code Generation =================================
    // await this.reviewService.getPaymentNumber(this.totalCheckedArrayforApproval[i]).toPromise().then((res: any) => {
    //   newPaymentNoArray.push(res.DynamicLfsPayCd.Result[0]);
    //   if (res.DynamicLfsPayCd.Result.length != 0) {

    //     if (Number(newPaymentNoArray[i].lfsPayNo) >= 12) {
    //       this.totalCheckedArrayforApproval[i].paymentnumber = '01';
    //     } else {
    //       this.totalCheckedArrayforApproval[i].paymentnumber = (Number(newPaymentNoArray[i].lfsPayNo) + 1).toString();
    //       if (this.totalCheckedArrayforApproval[i].paymentnumber.length == 1) {
    //         this.totalCheckedArrayforApproval[i].paymentnumber = "0" + this.totalCheckedArrayforApproval[i].paymentnumber
    //       }
    //     }
    //   }
    // },
    //   err => {
    //     console.log('Error in Dynamic LFS Code generation');
    //   });

    this.showSpinner = false;
    $('#ApproveModal').modal('show');
  }

  async approvePaymentFnLessthan30() {
    this.showSpinner = true;
    console.log('this.datemsismatch', this.dateMismatchArray)
    if (this.dateMismatchArray.length == 0) {
      console.log(this.selection);
      this.getAllChecked('none');
      this.getAllCheckedHold('none');
      this.getAllCheckedException('none');

      for (let i = 0; i < this.holdDataSource.data.length; i++) {
        this.holdDataSource.data[i].check = false;
        this.holdDataSource.data[i].toBeSaved = false;
        await this.reviewService.savePayments(this.holdDataSource.data[i]).toPromise().then(async (response: any) => {
          this.appService.unsavedChanges = false;
        });
      }
      var payNumberOne = []
      for (let i = 0; i < this.totalCheckedArrayforApproval.length; i++) {
        if (this.totalCheckedArrayforApproval[i].paymentnumber == '01') {
          payNumberOne.push(this.totalCheckedArrayforApproval[i]);
        }
      }
      await this.firstPaymentfunction(payNumberOne);
      for (let iOutput = 0; iOutput < this.totalCheckedArrayforApproval.length; iOutput++) {
        await this.reviewService.approvePayments(this.totalCheckedArrayforApproval[iOutput]).toPromise()
          .then(async (response: any) => {
            console.log(response);
            this.appService.category1 = [];
            this.appService.category2 = [];
            if (this.totalCheckedArrayforApproval.length - 1 == iOutput) {
              //this.isfrozen();
              this.paid = true;
              this.successMsg = 'The Payments will be posted to SAP';
              this.PaymentsApproval();
              this.PaymentsOnHold();
              this.Exception();
              //this.appComponent.getUnreadNotifications();
            }
            setTimeout(function () {
              this.paid = false;
            }.bind(this), 7000);
          });
      }
      console.log(this.totalCheckedArrayforHold);

      //  var flags = [], output = [], l = this.totalCheckedArrayforApproval.length, i;
      // for (i = 0; i < l; i++) {
      //   if (flags[this.totalCheckedArrayforApproval[i].DEALER_CODE]) continue;
      //   flags[this.totalCheckedArrayforApproval[i].DEALER_CODE] = true;
      //   // flags['PaymentType'] = this.totalCheckedArrayforApproval[i].paymentType;
      //   if (this.totalCheckedArrayforApproval[i].paymentType != '2') {
      //     output.push(this.totalCheckedArrayforApproval[i].DEALER_CODE);
      //   }
      // }
      // console.log('Output',output);
      // for (let iOutput = 0; iOutput < output.length; iOutput++) {
      //   await this.loginService.getUserDetailListWithSpinID().toPromise()
      //     .then(async (resultDetails: any) => {
      //       // this.appService.staffMasterDealerDetails = result.UserDetails.Result;
      //       let j = 0;
      //       for (let iVal = 0; iVal < resultDetails.UserDetails.Result.length; iVal++) {
      //         if (resultDetails.UserDetails.Result[iVal].dealerCode == output[iOutput] + '') {
      //           this.appService.staffMasterDealerDetails[j] = resultDetails.UserDetails.Result[iVal];
      //           j++;
      //         }
      //       }
      //       for (let iVal = 0; iVal < this.appService.staffMasterDealerDetails.length; iVal++) {
      //         if (this.appService.staffMasterDealerDetails[iVal].isPc.toUpperCase() == 'TRUE') {
      //           this.appService.category1.push(this.appService.staffMasterDealerDetails[iVal]);
      //         } else if (this.appService.staffMasterDealerDetails[iVal].jobDescription == 'DEALER PRINCIPAL') {
      //           this.appService.category2.push(this.appService.staffMasterDealerDetails[iVal]);
      //         }
      //       }
      //       console.log('category1', this.appService.category1);
      //       console.log('category2', this.appService.category2);
      //       // Call for notification Approve
      //       await this.notificationService.insertNotification('Payment has been approved by Lexus College', 'System_Payment',
      //         '125', '', 'Payment Approved', 'Payment has been approved by Lexus College.',
      //         'Info', 'Yes', "ActionLink", "SYSTEM", "").toPromise()
      //         .then(async (result: any) => {
      //           if (result.RowUpdated.rowCount == 1) {
      //             console.log('Notification Added');
      //             await this.notificationService.getMaxIdNotification().toPromise()
      //               .then(async (response: any) => {
      //                 var id = response.Id.Result[0].NOTIFY_ID
      //                 console.log(id);
      //                 for (let i = 0; i < this.appService.category1.length; i++) {
      //                   await this.notificationService.insertNotificationDetails(id,
      //                     this.appService.category1[i].spinId, 'lexusmasterlease@lexus.com', 'System_Payments',
      //                     'Payment Approved', 'Payment has been approved by Lexus College.',
      //                     'Info', 'N', 'N', "SYSTEM").toPromise()
      //                     .then(async (final: any) => {
      //                       console.log("Inserted Successfully");
      //                     }, err => {
      //                       console.log("Error in Inserting Notification");
      //                     });
      //                 }
      //                 for (let i = 0; i < this.appService.category2.length; i++) {
      //                   await this.notificationService.insertNotificationDetails(id,
      //                     this.appService.category2[i].spinId, 'lexusmasterlease@lexus.com', 'System_Payments',
      //                     'Payment Approved', 'Payment has been approved by Lexus College.',
      //                     'Info', 'N', 'N', "SYSTEM").toPromise()
      //                     .then(async (final: any) => {
      //                       console.log("Inserted Successfully");
      //                     }, err => {
      //                       console.log("Error in Inserting Notification");
      //                     });
      //                 }
      //                 for (let i = 0; i < this.appService.category5.length; i++) {
      //                   await this.notificationService.insertNotificationDetails(id,
      //                     this.appService.category5[i].workdayId, this.appService.category5[i].emailId, 'System_Payments',
      //                     'Payment Approved', 'Payment has been approved by Lexus College.',
      //                     'Info', 'N', 'N', "SYSTEM").toPromise()
      //                     .then(async (final: any) => {
      //                       console.log("Inserted Successfully");
      //                     }, err => {
      //                       console.log("Error in Inserting Notification");
      //                     });
      //                 }
      //               });
      //           }
      //         });
      //     });
      // }

      const identicalArray = [];
      const index = 1;
      const grouped = _.groupBy(this.totalCheckedArrayforHold, car => car.enrollment_no);
      _.each(
        _.sortBy(
          _.toArray(grouped), function (num) {
            return num;
          }
        ).reverse(),
        function (v) {
          identicalArray.push(v);
          console.log(v);
        }
      );
      console.log('grouped', identicalArray);
      for (let identicalVal = 0; identicalVal < identicalArray.length; identicalVal++) {
        console.log(identicalArray[identicalVal]);
        let tempDate = '';
        let minDate = '';
        let num = 0;
        if (identicalArray[identicalVal].length > 0) {
          for (let j = 0; j < identicalArray[identicalVal].length; j++) {
            const months: any = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
            const selectMonth = identicalArray[identicalVal][j].paymenttime.substring(0, 3).toUpperCase();
            const selectyear = parseInt(identicalArray[identicalVal][j].paymenttime.substring(4, 8));
            identicalArray[identicalVal][j].tempTime = new Date(selectyear, months.indexOf(selectMonth));

            tempDate = identicalArray[identicalVal][j].tempTime;
            if (minDate == '') {
              minDate = identicalArray[identicalVal][j].tempTime;
            } else {
              if (tempDate < minDate) {
                minDate = tempDate;
                num = j;
              }
            }
          }
          identicalArray[identicalVal] = identicalArray[identicalVal][num];
        }
      }
      console.log(identicalArray);
      for (let iHoldCount = 0; iHoldCount < identicalArray.length; iHoldCount++) {
        await this.reviewService.approvePaymentsHold(identicalArray[iHoldCount]).toPromise().then(async (response1: any) => {
          this.appService.category1 = [];
          this.appService.category2 = [];
          // await this.loginService.getUserDetailListWithSpinID().toPromise()
          //   .then(async (resultDetails: any) => {
          //     // this.appService.staffMasterDealerDetails = result.UserDetails.Result;
          //     let j = 0;
          //     for (let iVal = 0; iVal < resultDetails.UserDetails.Result.length; iVal++) {
          //       if (resultDetails.UserDetails.Result[iVal].dealerCode == identicalArray[iHoldCount].DEALER_CODE + '') {
          //         this.appService.staffMasterDealerDetails[j] = resultDetails.UserDetails.Result[iVal];
          //         j++;
          //       }
          //     }
          //     for (let iVal = 0; iVal < this.appService.staffMasterDealerDetails.length; iVal++) {
          //       if (this.appService.staffMasterDealerDetails[iVal].isPc.toUpperCase() == 'TRUE') {
          //         this.appService.category1.push(this.appService.staffMasterDealerDetails[iVal]);
          //       } else if (this.appService.staffMasterDealerDetails[iVal].jobDescription == 'DEALER PRINCIPAL') {
          //         this.appService.category2.push(this.appService.staffMasterDealerDetails[iVal]);
          //       }
          //     }
          //     console.log('category1', this.appService.category1);
          //     console.log('category2', this.appService.category2);
          //     await this.notificationService.insertNotification('Associate has been paid and removed from Hold', 'System_Payment',
          //       '12345', identicalArray[iHoldCount].DEALER_CODE, 'Payment paid and Approved', 'Associate ' + identicalArray[iHoldCount].associatename + ' has been paid for ' + identicalArray[iHoldCount].paymenttime + ' and removed from Hold.',
          //       'Info', 'Yes', "ActionLink", "SYSTEM", "").toPromise()
          //       .then(async (result: any) => {
          //         if (result.RowUpdated.rowCount == 1) {
          //           console.log('Notification Added');
          //           await this.notificationService.getMaxIdNotification().toPromise()
          //             .then(async (response: any) => {
          //               var id = response.Id.Result[0].NOTIFY_ID
          //               console.log(id);
          //               for (let i = 0; i < this.appService.category1.length; i++) {
          //                 this.notificationService.insertNotificationDetails(id,
          //                   this.appService.category1[i].spinId, 'lexusmasterlease@lexus.com', 'System_Payments',
          //                   'Payment paid and Approved', 'Associate ' + identicalArray[iHoldCount].associatename + ' has been paid for ' + identicalArray[iHoldCount].paymenttime + ' and removed from Hold.',
          //                   'Info', 'N', 'N', "SYSTEM").toPromise()
          //                   .then(async (final: any) => {
          //                     console.log('Notification Inserted');
          //                   }, err => {
          //                     console.log('Error in Notification Insert');
          //                   });
          //               }
          //               for (let i = 0; i < this.appService.category2.length; i++) {
          //                 this.notificationService.insertNotificationDetails(id,
          //                   await this.appService.category2[i].spinId, 'lexusmasterlease@lexus.com', 'System_Payments',
          //                   'Payment paid and Approved', 'Associate ' + identicalArray[iHoldCount].associatename + ' has been paid for ' + identicalArray[iHoldCount].paymenttime + ' and removed from Hold.',
          //                   'Info', 'N', 'N', "SYSTEM").toPromise()
          //                   .then(async (final: any) => {
          //                     console.log('Notification Inserted');
          //                   }, err => {
          //                     console.log('Error in Notification Insert');
          //                   });
          //               }
          //               for (let i = 0; i < this.appService.category3.length; i++) {
          //                 await this.notificationService.insertNotificationDetails(id,
          //                   this.appService.category3[i].spinId, 'lexusmasterlease@lexus.com', 'System_Payments',
          //                   'Payment paid and Approved', 'Associate ' + identicalArray[iHoldCount].associatename + ' has been paid for ' + identicalArray[iHoldCount].paymenttime + ' and removed from Hold.',
          //                   'Info', 'N', 'N', "SYSTEM").toPromise()
          //                   .then(async (final: any) => {
          //                     console.log('Notification Inserted');
          //                   }, err => {
          //                     console.log('Error in Notification Insert');
          //                   });
          //               }
          //               for (let i = 0; i < this.appService.category4.length; i++) {
          //                 await this.notificationService.insertNotificationDetails(id,
          //                   this.appService.category4[i].workdayId, this.appService.category4[i].emailId, 'System_Payments',
          //                   'Payment paid and Approved', 'Associate ' + identicalArray[iHoldCount].associatename + ' has been paid for ' + identicalArray[iHoldCount].paymenttime + ' and removed from Hold.',
          //                   'Info', 'N', 'N', "SYSTEM").toPromise()
          //                   .then(async (final: any) => {
          //                     console.log('Notification Inserted');
          //                   }, err => {
          //                     console.log('Error in Notification Insert');
          //                   });
          //               }
          //               for (let i = 0; i < this.appService.category5.length; i++) {
          //                 await this.notificationService.insertNotificationDetails(id,
          //                   this.appService.category5[i].workdayId, this.appService.category5[i].emailId, 'System_Payments',
          //                   'Payment paid and Approved', 'Associate ' + identicalArray[iHoldCount].associatename + ' has been paid for ' + identicalArray[iHoldCount].paymenttime + ' and removed from Hold.',
          //                   'Info', 'N', 'N', "SYSTEM").toPromise()
          //                   .then(async (final: any) => {
          //                     console.log('Notification Inserted');
          //                     if (identicalArray.length - 1 == iHoldCount) {
          //                       // this.isfrozen();
          //                       this.PaymentsApproval();
          //                       this.PaymentsOnHold();
          //                       this.Exception();
          //                       this.paid = true;
          //                       this.successMsg = 'The Payments will be posted to Peoplesoft';
          //                       this.appComponent.getUnreadNotifications();
          //                       setTimeout(function () {
          //                         this.paid = false;
          //                       }.bind(this), 7000);
          //                     }
          //                   }, err => {
          //                     console.log('Error in Notification Insert');
          //                   });
          //               }
          //             });
          //         }
          //       });
          //   });
        });
      }
      //this.isfrozen();
      this.paid = true;
      this.successMsg = 'The Payments will be posted to SAP';
      setTimeout(function () {
        this.paid = false;
      }.bind(this), 7000);
      this.showSpinner = false;

      var flags1 = [], output1 = [], l = identicalArray.length, i;
      for (i = 0; i < l; i++) {
        if (flags1[identicalArray[i].DEALER_CODE]) continue;
        flags1[identicalArray[i].DEALER_CODE] = true;
        output1.push(identicalArray[i].DEALER_CODE);
      }
      console.log(output1);

    } else {
      this.showSpinner = false;
      this.freezeMonth = this.dateMismatchArray[0].paymenttime.substring(0, 3);
      $("#pastPayemntModal").removeClass('hide_block');
      $("#pastPayemntModal").addClass('display_block');
      $("#pastPayemntModal").show("slow");
    }
  }

  async approvePaymentFnMorethan30() {
    this.showSpinner = true;
    let totalCount = '';
    let payIds = '';
    for (let user of this.totalCheckedArrayforApproval) {
      payIds += user.pay_id + ', ';
    }
    console.log('payIds ', payIds.slice(0, payIds.length - 2));
    console.log('this.dateMismatchArray in Bulk ', this.dateMismatchArray);

    if (this.dateMismatchArray.length == 0) {
      console.log(this.selection);
      this.getAllChecked('none');
      this.getAllCheckedHold('none');
      this.getAllCheckedException('none');

      let finalPayId = payIds.slice(0, payIds.length - 2);
      console.log('finalpayids :: ', finalPayId);
      // first service call to check selected pays
      await this.reviewService.bulkApprovePayments(finalPayId).toPromise().then(async (bulkResponse: any) => {
        console.log('Bulk Save Response ', bulkResponse.RowUpdated.rowCount);

        // second call to insert them in pay history table
        await this.reviewService.bulkInsertPayments('').toPromise().then(async (bulkInserResponse: any) => {
          console.log('Bulk Insert Response ', bulkInserResponse.RowUpdated.rowCount);
          totalCount = bulkInserResponse.RowUpdated.rowCount;

          // third call to update renewal date for 1st payments
          await this.reviewService.bulkUpdateRenewalDate('').toPromise().then(async (bulkInserResponse: any) => {
            console.log('Bulk Updating Renewal Date -  ', bulkInserResponse.RowUpdated.rowCount);
            //totalCount = bulkInserResponse.RowUpdated.rowCount;

            // fourth call to update statuses to approved for all the selected payments
            await this.reviewService.bulkUpdateStatus('').toPromise().then(async (bulkUpdateResponse: any) => {
              console.log('Bulk Update Status Response ', bulkUpdateResponse.RowUpdated.rowCount);

              // last call to uncheck the selected pays
              await this.reviewService.bulkUpdateChecked('').toPromise().then(async (bulkUpdateCheckResponse: any) => {
                console.log('Bulk Update Check Response ', bulkUpdateCheckResponse.RowUpdated.rowCount);
                var ele = <HTMLInputElement>document.getElementById("allCheckBox");
                ele.checked = false;
                this.successMsg = totalCount + ' Payment(s) will be posted to SAP';

                const dt = new Date().toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).split('/').reverse().join('-');
                const tm = new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
                console.log('dt: ', dt, typeof (dt), 'tm: ', tm, typeof (tm));
                var dttm: String = ""; dttm = dttm.concat(dt.toString()).concat("_").concat(tm.toString());
                console.log('dttm :: ', dttm)

                await this.reviewService.postToSapTrig(dttm).toPromise().then(async (sapTrigRes: any) => {
                  console.log('sapTrigRes - ', sapTrigRes.storeIt);
                  if (sapTrigRes.storeIt == "upload done") {
                    console.log('upload success');
                    this.successMsg = totalCount + ' Payment(s) will be posted to SAP';
                  } else if (sapTrigRes.storeIt == "upload undone") {
                    console.log('upload failed');
                    this.successMsg = 'Payments were approved but there was a problem posting them to SAP';
                  }
                }, err => {
                  console.log('sap trig fn error');
                  this.successMsg = 'Payments were approved but there was a problem posting them to SAP';
                })
              }, err => {
                console.log('Error in  trying to bulk update checked');
                this.successMsg = 'Sorry! An error occured while approving payments.';
              });
            }, err => {
              console.log('Error in  trying to bulk update status');
              this.successMsg = 'Sorry! An error occured while approving payments.';
            });
          }, err => {
            console.log('Error in  trying to bulk update renewal date');
            this.successMsg = 'Sorry! An error occured while approving payments.';
          });
        }, err => {
          console.log('Error in  trying to bulk insert the approve payment');
          this.successMsg = 'Sorry! An error occured while approving payments.';
        });
      }, err => {
        console.log('Error in  trying to bulk Save the approve payment');
        this.successMsg = 'Sorry! An error occured while approving payments. Please try again.';
      });

      const identicalArray = [];
      const index = 1;
      const grouped = _.groupBy(this.totalCheckedArrayforHold, car => car.enrollment_no);
      _.each(
        _.sortBy(
          _.toArray(grouped), function (num) {
            return num;
          }
        ).reverse(),
        function (v) {
          identicalArray.push(v);
          console.log(v);
        }
      );
      console.log('grouped More than 40 ', identicalArray);
      for (let identicalVal = 0; identicalVal < identicalArray.length; identicalVal++) {
        console.log(identicalArray[identicalVal]);
        let tempDate = '';
        let minDate = '';
        let num = 0;
        if (identicalArray[identicalVal].length > 0) {
          for (let j = 0; j < identicalArray[identicalVal].length; j++) {
            const months: any = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
            const selectMonth = identicalArray[identicalVal][j].paymenttime.substring(0, 3).toUpperCase();
            const selectyear = parseInt(identicalArray[identicalVal][j].paymenttime.substring(4, 8));
            identicalArray[identicalVal][j].tempTime = new Date(selectyear, months.indexOf(selectMonth));

            tempDate = identicalArray[identicalVal][j].tempTime;
            if (minDate == '') {
              minDate = identicalArray[identicalVal][j].tempTime;
            } else {
              if (tempDate < minDate) {
                minDate = tempDate;
                num = j;
              }
            }
          }
          identicalArray[identicalVal] = identicalArray[identicalVal][num];
        }
      }
      console.log(identicalArray);
      for (let iHoldCount = 0; iHoldCount < identicalArray.length; iHoldCount++) {
        await this.reviewService.approvePaymentsHold(identicalArray[iHoldCount]).toPromise().then(async (response1: any) => {
          console.log('Hold Approve ', response1);
        });
      }
      this.showSpinner = false;
      console.log('Inserted Rows ', totalCount);
      this.paid = true;
      // this.successMsg = totalCount + ' Payments will be posted to Peoplesoft';
      this.PaymentsApproval();
      this.PaymentsOnHold();
      this.Exception();
      setTimeout(function () {
        this.paid = false;
      }.bind(this), 7000);
      this.showSpinner = false;
    } else {
      this.showSpinner = false;
      this.freezeMonth = this.dateMismatchArray[0].paymenttime.substring(0, 3);
      // $("#pastPayemntModal").removeClass('hide_block');
      // $("#pastPayemntModal").addClass('display_block');
      // $("#pastPayemntModal").show("slow");
    }
  }

  async firstPaymentfunction(array) {
    if (array.length > 0) {
      for (let i = 0; i < array.length; i++) {
        await this.reviewService.firstTimePayment(array[i], false).toPromise().then(async (response: any) => {
          console.log(response);
        })
      }
    }
  }

  dontFreeze() {
    this.dateMismatchArray = [];
    this.approvePaymentFn();
    $("#pastPayemntModal").removeClass('display_block');
    $("#pastPayemntModal").addClass('hide_block');
    $("#pastPayemntModal").show("slow");
  }

  approvePaymentFn() {
    this.dateMismatchArray = [];
    this.transferAlert = false;
    this.approvePaymentFnMorethan30();
  }

  freeze() {
    this.dateMismatchArray = [];
    this.reviewService.freeze().subscribe((response1: any) => {
      console.log(response1);
      this.approvePaymentFn();
      $("#pastPayemntModal").removeClass('display_block');
      $("#pastPayemntModal").addClass('hide_block');
      $("#pastPayemntModal").show("slow");
    });
  }

  holdDataDetails(user, index) {
    if (!user.check) {
      this.selectedDealer = true;
      this.selectOverRideCheck = index;
      this.selectedDealerDetails = user;
      console.log('this.selectedDealerDetails', this.selectedDealerDetails);
      $("#overrideHoldModal").removeClass('hide_block');
      $("#overrideHoldModal").addClass('display_block');
      $("#overrideHoldModal").show("slow");
    }
  }

  exceptionDataDetails(user) {
    this.exceptionSelectedDealer = true;
    this.exceptionSelectedDealerName = this.capitalize(user.Name);
  }


  // Add Manual Payment Functions

  filterId() {
    if (this.spinId != undefined) { this.spinId = this.spinId.replace(/[^a-zA-Z0-9 ]/g, ""); }
    if (this.spinId != undefined && this.spinId.length >= 3) {
      this.filteredUsers = [];
      this.isLoading = true;
      this.reviewService.getEnrolledUserSearch(this.spinId, this.appService.selectedRegion).subscribe((result: any) => {
        this.filteredUsers = result.HoldPaymentSearch.Result;
        this.isLoading = false;
      });
    }
  }

  focusFunction() {
    this.spinIdClicked = true;
    $('#tableSection').fadeTo('slow', .4);
    $('#tableSection').append('<div id="appendDiv" style="position: absolute;top:200px;left:0;width: 100%;height:100%;z-index:2;opacity:0.4;filter: alpha(opacity = 50)"></div>');
  }

  focusOutFunction() {
    if (this.spinId == "") {
      this.spinIdClicked = false;
      // $('#tableSection').fadeTo('slow', 1); 
    }
    else if (this.filteredUsers.length > 0 && this.filteredUsers != undefined) {
      // this.spinId='';
      // this.selectUser(any)   
    }
    else {
      this.closeSpinSearch();
    }
    $('#tableSection').fadeTo('slow', 1);
    $("#appendDiv").remove();
  }

  closeSpinSearch() {
    this.spinId = "";
    this.spinIdClicked = true;
  }

  selectUser(user) {
    console.log(new Date());
    console.log('user', user);
    this.closeAutoSearch = true;
    var name = '';
    this.manualPaymentUser = user;
    this.addPaymentList = [];
    this.reviewService.GetPcDetails(user.dealerCode).subscribe((response: any) => {
      console.log('PC details', response);
      this.pcDetails = response.PcDetails.Result;
    });
    this.reviewService.getPaymentList(user.spinId, user.enrollmentNo).subscribe((result1: any) => {
      console.log('Payment list', result1);
      this.payment_list = result1.PaymentListDetails.Result;
    });
    this.reviewService.getPaymentHistory(user.spinId, user.enrollmentNo).subscribe((result1: any) => {
      this.manualPayUser = { NAME: user.firstName.trim() + " " + user.lastName.trim(), SPINID: user.spinId, DEALERCODE: user.dealerCode, STARTPERIOD: 'OCT-2018', ENDPERIOD: 'OCT-2018', REASON: 'On Strike' };
      console.log('getPaymentHistory Response', result1);
      $('input[name="quantity"]').val(1);
      $("#appendDiv").remove();
      this.addPaymentList = [];
      this.isActiveProceed = false;
      this.isActiveLeaseProceed = false;
      this.newPay = 1;
      $('html,body').animate({ scrollTop: 0 }, "smooth");
      name = user.firstName.trim() + " " + user.lastName.trim();
      if (name != '') {
        this.spinId = this.capitalize(name.trim());
      }
      // this.spinId = this.capitalize(user.firstName.trim()) + " " + this.capitalize(user.lastName.trim());
      this.manualPaymentSection = true;
      this.manualPayUserDetails = result1.HoldPaymentSearch.Result;
      console.log('Manual Pay Search Result', this.manualPayUserDetails);
      if (this.manualPayUserDetails.length != 0) {
        this.noHist = false;
        this.noHistBtnDis = false;
        this.showNoDataPaymentHistory = false;
        for (let i = 0; i < this.manualPayUserDetails.length; i++) {
          this.manualPayUserDetails[i].PAYMENTNUMBER = parseInt(this.manualPayUserDetails[i].payNo, 10);
          this.manualPayUserDetails[i].PAYMENTPERIOD = this.manualPayUserDetails[i].payPeriod;
          this.manualPayUserDetails[i].LFSPAYCD = this.manualPayUserDetails[i].lfsPayCd;
          this.manualPayUserDetails[i].Remarks = this.manualPayUserDetails[i].remarks;
          this.manualPayUserDetails[i].DealerCode = this.manualPayUserDetails[i].dealerCode
        }
        const nextMonth = parseInt(this.manualPayUserDetails[this.manualPayUserDetails.length - 1].payPeriod.substring(5, 7));
        const nextYr = parseInt(this.manualPayUserDetails[this.manualPayUserDetails.length - 1].payPeriod.substring(0, 4));
        if (nextYr == this.d.getFullYear()) {
          this.presentYr = this.d.getFullYear();
          this.add = nextMonth - this.d.getMonth();
          this.addMon = this.months[this.d.getMonth()];
          this.addYr = this.d.getFullYear();
        }
        else {
          this.presentYr = nextYr;
          this.add = -12;
          this.addMon = this.monthsHold[nextMonth];
          this.addYr = nextYr;
        }
        if (nextMonth == 12) {
          this.presentMon = this.monthsHold[0];
          this.presentYr = parseInt(this.presentYr) + 1;
        } else {
          this.presentMon = this.monthsHold[nextMonth];
        }

        // debugger;
        if (this.payment_list.length > 0 && this.payment_list.length < 12) {
          // this.newPay = Math.max(this.manualPayUserDetails[this.manualPayUserDetails.length - 1].PAYMENTNUMBER,
          //   parseInt(this.payment_list[this.payment_list.length - 1].payNumber), 0) + 1;
          let historyperiod = this.manualPayUserDetails[this.manualPayUserDetails.length - 1].payPeriod.split('-')[1];
          let historyperiodYr = this.manualPayUserDetails[this.manualPayUserDetails.length - 1].payPeriod.split('-')[0];
          let listperiod = this.payment_list[this.payment_list.length - 1].payPeriod.split('-')[0].toUpperCase();
          let listperiodYr = this.payment_list[this.payment_list.length - 1].payPeriod.split('-')[1];
          let listnos = this.monthsHold.indexOf(listperiod);
          let histnos = parseInt(historyperiod) - 1;
          let compare = Math.max(listnos, histnos);
          if (Number(listperiodYr) > Number(historyperiodYr)) {
            this.newPay = parseInt(this.payment_list[this.payment_list.length - 1].payNumber) + 1;
            (listnos != 11) ? this.presentMon = this.monthsHold[listnos + 1] : this.presentMon = this.monthsHold[0];
            this.presentYr = listperiodYr.toString();
          }
          else {
            if (compare == listnos) {
              this.newPay = parseInt(this.payment_list[this.payment_list.length - 1].payNumber) + 1;
              (listnos != 11) ? this.presentMon = this.monthsHold[listnos + 1] : this.presentMon = this.monthsHold[0];
              let yr = this.payment_list[this.payment_list.length - 1].payPeriod.split('-')[1];
              if (this.presentMon == 'JAN' && this.presentYr != this.d.getFullYear()) {
                this.presentYr = (Number(yr) + 1).toString();
              }
            } else {
              this.newPay = this.manualPayUserDetails[this.manualPayUserDetails.length - 1].PAYMENTNUMBER + 1;
            }
          }
        } else {
          this.newPay = this.manualPayUserDetails[this.manualPayUserDetails.length - 1].PAYMENTNUMBER + 1;
        }

        console.log('this.newPay1', this.newPay)
        this.countNumber = this.newPay;
        if (this.newPay > 12) {
          this.newPay = 1;
        }
        console.log('this.newPay2', this.newPay)
        if (this.presentYr == this.d.getFullYear()) {
          const newMonth = [];
          for (let i = 0; i <= this.d.getMonth(); i++) {
            newMonth[i] = this.months[i];
          }
          this.months = newMonth;
        }
        else {
          this.months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
        }

        if (this.months.indexOf(this.presentMon) == -1) {
          this.presentMon = this.months[this.months.length - 1];
        }
        const body = {
          'PaymentNumber': this.newPay,
          'PaymentMonth': this.presentMon,
          'PaymentYr': this.presentYr,
          'Remarks': '',
          'months': this.months
        };
        this.addPaymentList.push(body);
        console.log('Added Payment List Search not zero length', this.addPaymentList);
      }
      else {
        this.noHistBtnDis = false;
        this.noHist = true;
        this.showNoDataPaymentHistory = true;
        const sel = {
          paymentDate: new Date().toLocaleDateString(),
          spinId: user.spinId
        }
        console.log(sel);
        this.reviewService.manualIsActiveLease(sel).subscribe((lfsDateResponse: any) => {//user in bracket
          console.log(lfsDateResponse);
          if (lfsDateResponse.ActiveLease.Result[0].status == 'true') {
            let leaseMon = lfsDateResponse.ActiveLease.Result[0].activationDate.substring(5, 7);
            const leaseYr = lfsDateResponse.ActiveLease.Result[0].activationDate.substring(0, 4);
            this.presentYr = parseInt(leaseYr);
            this.presentMon = this.months[parseInt(leaseMon)];
            this.addMon = this.months[this.d.getMonth() - 2];
            this.add = -12;
            this.addYr = leaseYr;
            // debugger;
            if (this.payment_list.length > 0 && this.payment_list.length < 12) {
              this.newPay = Math.max(0, parseInt(this.payment_list[this.payment_list.length - 1].payNumber)) + 1;
            } else {
              this.newPay = 1;
            }
            this.countNumber = this.newPay;
            const body = {
              'PaymentNumber': this.newPay,
              'PaymentMonth': this.presentMon,
              'PaymentYr': this.presentYr,
              'Remarks': '',
              'months': this.months
            };
            this.addPaymentList.push(body);
            console.log('Added Payment List Manual Pay Search zero length && has active lease', this.addPaymentList);
          }
          else {
            if (this.d.getMonth() == 0) {
              this.presentMon = this.months[10];
              this.presentYr = this.d.getFullYear() - 1;
            }
            if (this.d.getMonth() == 1) {
              this.presentMon = this.months[11];
              this.presentYr = this.d.getFullYear() - 1;
            }
            else {
              this.presentMon = this.months[this.d.getMonth() - 2];
              this.presentYr = this.d.getFullYear();
            }
            this.addMon = this.presentMon;
            this.add = -2;
            this.addYr = this.presentYr;
            // debugger;
            if (this.payment_list.length > 0 && this.payment_list.length < 12) {
              this.newPay = Math.max(0, parseInt(this.payment_list[this.payment_list.length - 1].payNumber)) + 1;
            } else {
              this.newPay = 1;
            }
            this.countNumber = this.newPay;
            const body = {
              'PaymentNumber': this.newPay,
              'PaymentMonth': this.presentMon,
              'PaymentYr': this.presentYr,
              'Remarks': '',
              'months': this.months
            };
            this.addPaymentList.push(body);
            console.log('Added Payment List Manual Pay Search zero length && no active lease', this.addPaymentList);
          }

          if (this.presentYr == this.d.getFullYear()) {
            const newMonth = [];
            for (let i = 0; i <= this.d.getMonth(); i++) {
              newMonth[i] = this.months[i];
            }
            this.months = newMonth;
          }
          else {
            this.months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
          }
        });
      }
      // this.years = ['2019', '2018', '2017', '2016'];
    });
  }

  addQty(event) {
    if (this.newPay < 12 && this.add < 0) {
      event.preventDefault();
      var currentVal = parseInt($('input[name="quantity"]').val());
      console.log(currentVal)
      if (!isNaN(currentVal)) {
        this.newPay += 1;
        $('input[name="quantity"]').val(currentVal + 1);
        this.countNumber += 1;
        this.add += 1;
        let mon = this.months.indexOf(this.presentMon) + 1;
        if (mon > 11 && mon < 24) {
          mon = mon - 12;
        } else if (mon > 23) {
          mon = mon - 24
        }
        this.addMon = this.months[mon];
        if (this.addMon == 'JAN') {
          let yr = this.addPaymentList[this.addPaymentList.length - 1].PaymentYr;
          this.addYr = yr + 1;
        }
        // else {
        //   this.addYr = this.addYr;
        // }
        this.presentMon = this.addMon;
        this.presentYr = this.addYr;
      } else {
        $('input[name="quantity"]').val(0);
      }
      const body = {
        'PaymentNumber': this.newPay,
        'PaymentMonth': this.presentMon,
        'PaymentYr': this.presentYr,
        'Remarks': '',
        'months': this.months
      };
      this.addPaymentList.push(body);
      console.log('Added Payment List in addQty func', this.addPaymentList);
    }
  }

  minusQty(event) {
    event.preventDefault();
    var currentVal = parseInt($('input[name="quantity"]').val());
    if (!isNaN(currentVal) && currentVal > 1) {
      this.newPay -= 1;
      $('input[name="quantity"]').val(currentVal - 1);
      this.countNumber -= 1;
      this.add -= 1;
      this.addPaymentList.pop();
    } else {
      $('input[name="quantity"]').val(1);
    }
  }

  payNochange(i, event) {
    console.log('change', i + event.target.value);
    this.addPaymentList[i].PaymentNumber = parseInt(event.target.value);
    this.enteredPayNo = parseInt(event.target.value);
  }

  monthChange(i, event) {
    console.log('change', i + event.target.value);
    this.addPaymentList[i].PaymentMonth = event.target.value;
  }

  yearChange(i, event) {
    console.log('change', i);
    this.addPaymentList[i].PaymentYr = event.target.value;
    console.log(this.addPaymentList[i].PaymentYr);
    console.log(this.d.getFullYear());
    if (this.addPaymentList[i].PaymentYr != this.d.getFullYear()) {
      this.addPaymentList[i].months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    }
    else {
      const newMonth = [];
      for (let j = 0; j <= this.d.getMonth(); j++) {
        newMonth[j] = this.months[j];
      }
      this.addPaymentList[i].months = newMonth;
    }
  }

  makePayment() {
    this.remarksTouched = false;
    this.timeTouched = false;
    this.payNoTouched = false;
    this.userIsActiveMessage = '';
    this.leaseIsActiveMessage = '';
    this.dcMesage = '';
    this.maxMessage = '';
    this.message24 = '';
    this.message12 = '';
    this.autoEnrollErrorMsg = '';
    this.duplicatePaymentMessage = '';
    this.duplicatePaymentHistoryMessage = '';
    this.jobCodeMessage = '';
    console.log('AddPaymentList ', this.addPaymentList);
    //add payment Error
    var fname = '', nameArr = [], fullname = '', name = '';
    name = this.manualPayUser.NAME.split(' ');
    for (let i = 0; i < name.length; i++) {
      if (name[i].trim() != '') {
        fname = this.capitalize(name[i].trim());
        nameArr.push(fname);
      }
    }
    for (let i = 0; i < nameArr.length; i++) {
      fullname += nameArr[i] + ' ';
    }
    //
    this.manualPayerName = fullname.trim();
    this.monthsPayment = '';
    const array = [];
    const payLengthArray = [];
    for (let i = 0; i < this.addPaymentList.length; i++) {
      this.addPaymentList[i].remarksTouched = false;
      this.addPaymentList[i].validationTime = this.addPaymentList[i].PaymentMonth + ' ' + this.addPaymentList[i].PaymentYr;
      this.addPaymentList[i].timeTouched = false;
      this.addPaymentList[i].payNoTouched = false;
      array[i] = this.addPaymentList[i].PaymentMonth + ' ' + this.addPaymentList[i].PaymentYr;
      payLengthArray[i] = this.addPaymentList[i].PaymentNumber;
      if (this.addPaymentList[i].Remarks == '') {
        this.monthsPayment = 'Provide remarks before proceeding.';
        this.addPaymentList[i].remarksTouched = true;
        this.remarksTouched = true;
        // data-toggle="modal" data-target="#manualPaymentModal;
      } else {
        this.monthsPayment += this.capitalize(this.addPaymentList[i].PaymentMonth) + '-' + this.addPaymentList[i].PaymentYr + ' ';
        if (i == this.addPaymentList.length - 2) {
          this.monthsPayment += ' and ';
        } else if (this.addPaymentList.length > 2 && i != this.addPaymentList.length - 1) {
          this.monthsPayment += ', ';
        }
      }
    }

    const dupes = {};
    this.addPaymentList.forEach((item, index) => {
      dupes[item.validationTime] = dupes[item.validationTime] || [];
      dupes[item.validationTime].push(index);
    });
    console.log(dupes);
    var localArr = [];
    for (let name in dupes) {
      console.log(name + '->indexes->' + dupes[name] + '->count->' + dupes[name].length);
      console.log(dupes[name]);
      localArr.push(dupes[name]);
    }
    console.log('localArr', localArr);
    let indexes = [];
    for (let i = 0; i < localArr.length; i++) {
      console.log('localarr[i]', localArr[i]);
      if (localArr[i].length > 1) {
        indexes.push(localArr[i]);
      }
    }
    var singleArr = [];
    for (let i = 0; i < indexes.length; i++) {
      for (let j = 0; j < indexes[i].length; j++) {
        console.log('ij', indexes[i][j]);
        singleArr.push(indexes[i][j]);
      }
    }

    for (let i = 0; i < singleArr.length; i++) {
      this.addPaymentList[singleArr[i]].timeTouched = true;
    }

    const dupesPayNo = {};
    this.addPaymentList.forEach((item, index) => {
      dupesPayNo[item.PaymentNumber] = dupesPayNo[item.PaymentNumber] || [];
      dupesPayNo[item.PaymentNumber].push(index);
    });
    console.log(dupesPayNo);
    var localArrPayNo = [];
    for (let name in dupesPayNo) {
      console.log(name + '->indexes->' + dupesPayNo[name] + '->count->' + dupesPayNo[name].length);
      console.log(dupesPayNo[name]);
      localArrPayNo.push(dupesPayNo[name]);
    }
    console.log('localArrPayNo', localArrPayNo);
    let indexesPayNo = [];
    for (let i = 0; i < localArrPayNo.length; i++) {
      console.log('localArrPayNo[i]', localArrPayNo[i]);
      if (localArrPayNo[i].length > 1) {
        indexesPayNo.push(localArrPayNo[i]);
      }
    }
    var singleArrPayNo = [];
    for (let i = 0; i < indexesPayNo.length; i++) {
      for (let j = 0; j < indexesPayNo[i].length; j++) {
        console.log('ij', indexesPayNo[i][j]);
        singleArrPayNo.push(indexesPayNo[i][j]);
      }
      // singleArr[i] = indexes[i]
    }

    for (let i = 0; i < singleArrPayNo.length; i++) {
      this.addPaymentList[singleArrPayNo[i]].payNoTouched = true;
    }
    console.log('indexes', singleArrPayNo);
    console.log(this.addPaymentList);

    if (this.monthsPayment.indexOf("Provide") != -1) {
      $("#manualPaymentModal").removeClass('display_block');
      $("#manualPaymentModal").addClass('hide_block');
      $("#manualPaymentModal").show("slow");
    }
    if ((new Set(array)).size !== this.addPaymentList.length) {
      $("#manualPaymentModal").removeClass('display_block');
      $("#manualPaymentModal").addClass('hide_block');
      $("#manualPaymentModal").show("slow");
      this.timeTouched = true;
    }
    if ((new Set(payLengthArray)).size !== this.addPaymentList.length) {
      $("#manualPaymentModal").removeClass('display_block');
      $("#manualPaymentModal").addClass('hide_block');
      $("#manualPaymentModal").show("slow");
      this.payNoTouched = true;
    }
    if (this.monthsPayment.indexOf("Provide") == -1 && (new Set(array)).size == this.addPaymentList.length
      && (new Set(payLengthArray)).size == this.addPaymentList.length) {
      this.remarksTouched = false;
      $("#manualPaymentModal").removeClass('hide_block');
      $("#manualPaymentModal").addClass('display_block');
      $("#manualPaymentModal").show("slow");
      this.success();
    }
  }

  closeMakePaymentModal() {
    this.disabledManualPayment = false;
    $("#manualPaymentModal").removeClass('display_block');
    $("#manualPaymentModal").addClass('hide_block');
    $("#manualPaymentModal").show("slow");
  }

  async success() {
    // this.showSpinner = true;
    // console.log(this.addPaymentList);
    // this.manualPaymentUser = [];
    // for spinner
    this.userCheck = true;
    this.leaseCheck = true;
    this.dcCheck = true;
    this.paymentCheck = true;
    this.paymentHistoryCheck = true;
    this.check12 = true;
    this.check24 = true;
    this.maximumCheck = true;
    this.jobcodeCheck = true;

    // for tick and cross
    this.successLease = false;
    this.successdc = false;
    this.successUser = false;
    this.warnLease = false;
    this.warnUser = false;
    this.warndc = false;
    this.successPayment = '';
    this.successPaymentHistory = '';
    this.warnPayment = '';
    this.warnPaymentHistory = '';
    this.warn12 = ''
    this.warn24 = ''
    this.success12 = '';
    this.success24 = '';
    this.successMaximum = false;
    this.warnMaximum = false;
    this.successJobCode = false;
    this.warnJobCode = false;
    this.LFSErrorMsg = '';
    this.warnLFS = false;
    this.LFSFirstErrorMsg = '';
    this.warnFirstLFS = false;

    // for disabling button
    this.disabledManualPayment = true;
    this.validatingManualPay = false;
    await this.isActive(this.addPaymentList);
  }

  async isActive(item: any) {
    this.manualPaymentUser.remarks = item[0].Remarks;
    this.manualPaymentUser.PaymentMonth = item[0].PaymentMonth;
    this.manualPaymentUser.PaymentNumber = item[0].PaymentNumber;
    this.manualPaymentUser.PaymentYr = item[0].PaymentYr;
    this.manualPaymentUser.associatename = this.manualPaymentUser.firstName.trim() + ' ' + this.manualPaymentUser.lastName.trim();

    let dateForming = '01' + item[0].PaymentMonth + ' ' + item[0].PaymentYr; console.log('dateformin:',dateForming);
    // let dateIso = new Date(dateForming).toISOString().split('T')[0];
    let dateIso = new Date(dateForming).toISOString().split('T')[0]; 
    console.log('dateformin in iso',dateIso);
    let offset = new Date(dateForming).getTimezoneOffset();
    let newtime = new Date(new Date(dateForming).getTime() - (offset*60*1000));
    console.log('dateforming offset:',offset,'act time', newtime.toISOString().split('T')[0] );
    // this.manualPaymentUser.paymentDate = dateIso;
    this.manualPaymentUser.paymentDate = newtime.toISOString().split('T')[0];
    console.log('manualPaymentUser ', this.manualPaymentUser);
    await this.reviewService.manualIsActive(this.manualPaymentUser).toPromise()
      .then(async (isActiveResult: any) => {
        console.log('manualIsActive ', isActiveResult);
        this.userCheck = false;
        if (isActiveResult.AssociateEmployeeStatus.Result[0].status == 'Active' || this.isActiveProceed) {
          this.successUser = true;
          this.userIsActiveMessage = this.manualPaymentUser.firstName.trim() + ' ' + this.manualPaymentUser.lastName.trim() + ' is an Active User';
          this.warnUser = false;
        } else {
          this.successUser = false;
          this.userIsActiveMessage = this.manualPaymentUser.firstName.trim() + ' ' + this.manualPaymentUser.lastName.trim() + ' is no more Active';
          this.warnUser = true;
        }
        await this.jobCodeValidation();
      }, err => {
        console.log('Error while trying to check active status :: ' + err);
        this.jobCodeValidation();
      });
  }

  async jobCodeValidation() {
    await this.reviewService.manualPaymentjobCodeValidation(this.manualPaymentUser).toPromise()
      .then(async (result: any) => {
        this.jobcodeCheck = false;
        if (result.JobCodeStatus.Result[0].status == 'Assocaite is active and having valid job code for payment') {
          this.jobCodeMessage = 'Associate is active and having valid job code for payment';
          this.successJobCode = true;
          this.warnJobCode = false;
        } else {
          this.jobCodeMessage = result.JobCodeStatus.Result[0].status;
          this.successJobCode = false;
          this.warnJobCode = true;
          // this.disabledManualPayment = true;
          // this.validatingManualPay = true;
        }
        await this.activeLeaseValidation();
      }, err => {
        console.log('Error while trying to validate the job code :: ' + err);
        this.activeLeaseValidation();
      });
  }

  async activeLeaseValidation() {
    await this.reviewService.manualIsActiveLease(this.manualPaymentUser).toPromise()
      .then(async (isActiveResult: any) => {
        console.log('manualIsActiveLease ', isActiveResult);
        this.leaseCheck = false;
        if (isActiveResult.ActiveLease.Result[0].status == 'Lease Active' || this.isActiveLeaseProceed) {
          this.successLease = true;
          this.warnLease = false;
          this.leaseIsActiveMessage = 'The Associate has an Active Lease';
          this.manualPaymentUser.vin = isActiveResult.ActiveLease.Result[0].vin;
          this.manualPaymentUser.ctype = isActiveResult.ActiveLease.Result[0].ctype;
          this.dealerCodevalidation();
        } else {
          this.successLease = false;
          // this.leaseIsActiveMessage = 'There is no Active Lease for this Associate';
          this.leaseIsActiveMessage = isActiveResult.ActiveLease.Result[0].status;
          this.warnLease = true;
          // this.disabledManualPayment = true;
          //this.validatingManualPay = true;
          this.dealerCodevalidation();
        }
      }, err => {
        console.log('Error while trying to validate active lease :: ' + err);
        this.dealerCodevalidation();
      });
  }

  async dealerCodevalidation() {
    await this.reviewService.dealerCodeValidation(this.manualPaymentUser).toPromise()
      .then(async (result: any) => {
        console.log('dealerCodeValidation', result.DealerCodeValidation.Result);
        this.dcCheck = false;
        if (result.DealerCodeValidation.Result[0].Result == 'Valid') {
          this.dcMesage = 'Current dealership is valid.';
          this.successdc = true;
          this.warndc = false;
        }
        // else if (result.DealerCodeValidation.Result[0].Result == 'false'
        //   && result.ActiveLease.Result[0].paymentListandHistoryValidation == 'Valid') {
        //   this.dcMesage = 'Current dealership is same as last payment dealership of the associate';
        //   this.successdc = true;
        //   this.warndc = false;
        //   this.paymentValidation();
        // } 
        else {
          this.dcMesage = result.DealerCodeValidation.Result[0].Result;
          this.successdc = false;
          this.warndc = true;
          // this.disabledManualPayment = true;
          //this.validatingManualPay = true;
        }
        this.paymentValidation();
      }, err => {
        console.log('Error while trying to validate dealer code :: ' + err);
        this.paymentValidation();
      })
  }

  async paymentValidation() {
    for (let i = 0; i < this.addPaymentList.length; i++) {
      this.manualPaymentUser.remarks = this.addPaymentList[i].Remarks;
      this.manualPaymentUser.PaymentMonth = this.addPaymentList[i].PaymentMonth;
      this.manualPaymentUser.PaymentNumber = this.addPaymentList[i].PaymentNumber;
      this.manualPaymentUser.PaymentYr = this.addPaymentList[i].PaymentYr;
      this.manualPaymentUser.associatename = this.manualPaymentUser.firstName.trim() + ' ' + this.manualPaymentUser.lastName.trim();
      await this.reviewService.manuaIsPaymentDuplicate(this.manualPaymentUser).toPromise()
        .then(async (isActiveResult: any) => {
          console.log('manuaIsPaymentDuplicate ', isActiveResult);
          this.paymentCheck = false;
          this.paymentHistoryCheck = false;
          if (isActiveResult.ValidationCheck.Result[0].paymentListCount == 0) {
            this.successPayment += 'true';
            this.warnPayment += 'false';
          } else {
            this.successPayment += 'false';
            this.warnPayment += 'true';
          }
          if (isActiveResult.ValidationCheck.Result[0].paymentHistoryCount == 0) {
            this.successPaymentHistory += 'true';
            this.warnPaymentHistory += 'false';
          } else {
            this.successPaymentHistory += 'false';
            this.warnPaymentHistory += 'true';
          }
          console.log(this.successPaymentHistory);
          console.log(this.warnPaymentHistory);
          if (this.successPayment.indexOf('false') != -1) {
            this.duplicatePaymentMessage = 'Payment has already been created for the selected Payment Period and is awaiting approval.';
            this.validatingManualPay = true;
          } else {
            this.duplicatePaymentMessage = 'The selected payment period is not in Payment List';
          }
          if (i == this.addPaymentList.length - 1) {
            if (this.successPaymentHistory.indexOf('false') != -1) {
              this.duplicatePaymentHistoryMessage = 'Payment has already been created for the selected Payment Period.';
              // this.disabledManualPayment = true; // change to true!!!
              this.validatingManualPay = true; // un-comment here. else everything will fail.
            } else {
              if (!this.validatingManualPay) {
                // this.disabledManualPayment = false;
              }
              this.duplicatePaymentHistoryMessage = 'The selected payment period is not in Payment History';
            }
            if (this.manualPaymentUser.enrollmentNo.startsWith('IE')) {
              this.twentyfourMonthValidation();
            } else {
              this.twelveMonthValidation();
            }
          }
        }, err => {
          console.log('Error while trying to validate payment duplication :: ' + err);
          this.twentyfourMonthValidation();
        });
    }
  }

  async twentyfourMonthValidation() {
    for (let i = 0; i < this.addPaymentList.length; i++) {
      this.manualPaymentUser.remarks = this.addPaymentList[i].Remarks;
      this.manualPaymentUser.PaymentMonth = this.addPaymentList[i].PaymentMonth;
      this.manualPaymentUser.PaymentNumber = this.addPaymentList[i].PaymentNumber;
      this.manualPaymentUser.PaymentYr = this.addPaymentList[i].PaymentYr;
      this.manualPaymentUser.associatename = this.manualPaymentUser.firstName.trim() + ' ' + this.manualPaymentUser.lastName.trim();
      await this.reviewService.twentyfourMonthValidation(this.manualPaymentUser).toPromise()
        .then(async (isActiveResult: any) => {
          console.log('response', isActiveResult);
          this.check24 = false;
          if (isActiveResult.AutoEnrollValidation.Result[0].status == 'Valid') {
            this.success24 += 'true';
            this.warn24 += 'false';
          } else {
            this.success24 += 'false';
            this.warn24 += 'true';
          }
          if (i == this.addPaymentList.length - 1) {
            if (this.success24.indexOf('false') != -1) {
              this.message24 = 'Payment Month is more than 24 months from enrollment'; // **
              this.autoEnrollErrorMsg = "**When you click on 'Create Enrollment', an auto enrollment will be created.";
            } else {
              this.message24 = 'Payment Month is less than 24 months from enrollment';
            }
            this.twelveMonthValidation();
          }
        }, err => {
          console.log('Error while trying to validate 24 months :: ' + err);
          this.twelveMonthValidation();
        });
    }
  }

  async twelveMonthValidation() {
    for (let i = 0; i < this.addPaymentList.length; i++) {
      this.manualPaymentUser.remarks = this.addPaymentList[i].Remarks;
      this.manualPaymentUser.PaymentMonth = this.addPaymentList[i].PaymentMonth;
      this.manualPaymentUser.PaymentNumber = this.addPaymentList[i].PaymentNumber;
      this.manualPaymentUser.PaymentYr = this.addPaymentList[i].PaymentYr;
      this.manualPaymentUser.associatename = this.manualPaymentUser.firstName.trim() + ' ' + this.manualPaymentUser.lastName.trim();
      console.log('manualPaymentUser', this.manualPaymentUser);
      await this.reviewService.twelveMonthValidation(this.manualPaymentUser).toPromise()
        .then(async (isActiveResult: any) => {
          console.log('response', isActiveResult);
          this.check12 = false;
          if (isActiveResult.AutoEnrollValidation.Result[0].status == 'Valid') {
            this.success12 += 'true';
            this.warn12 += 'false';
          } else {
            this.success12 += 'false';
            this.warn12 += 'true';
          }
          if (i == this.addPaymentList.length - 1) {
            if (this.success12.indexOf('false') != -1) {
              this.message12 = 'First payment must start within 12 months from enrollment';
              this.autoEnrollErrorMsg = "**When you click on 'Create Enrollment', an auto enrollment will be created.";
            } else {
              this.message12 = 'First payment starts within 12 months from enrollment';
            }
            this.maximumPaymentValidation();
          }
        }, err => {
          console.log('Error while trying to validate 12 months :: ' + err);
          this.maximumPaymentValidation();
        });
    }
  }

  async maximumPaymentValidation() {
    let PayNumber: any;
    await this.reviewService.maximumPayments(this.manualPaymentUser).toPromise()
      .then(async (result: any) => {
        this.maximumCheck = false;
        if (result.AutoEnrollValidation.Result[0].status == 'Valid') {
          this.maxMessage = 'Number of maximum payment is less than 12';
          this.successMaximum = true;
          this.warnMaximum = false;
        } else {
          this.maxMessage = 'Maximum number of payments reached';
          this.autoEnrollErrorMsg = "**When you click on 'Create Enrollment', an auto enrollment will be created.";
          this.successMaximum = false;
          this.warnMaximum = true;
        }
        if (this.autoEnrollErrorMsg != '') {
          PayNumber = 1;
        } else {
          PayNumber = this.newPay;
        }
        if (PayNumber != 1) {
          await this.getLFSPayCode();
        } else {
          await this.getLFSPayCodeforFirstPay();
        }
      }, err => {
        console.log('Error while trying to validate max payment :: ' + err);
        // this.disabledManualPayment = true;
        this.validatingManualPay = true;
        if (this.newPay != 1) {
          this.getLFSPayCode();
        } else {
          this.getLFSPayCodeforFirstPay();
        }
      });
  }

  async manualPayFlag(enrollmentNo, certLevel) {
    let autoEnrollEligible: any, flagUpdate: any;
    this.manualPayflagUpdateResponse = '';
    await this.reviewService.manualPayFlag(enrollmentNo, certLevel).toPromise().then(async (response: any) => {
      console.log('Manual Pay Flag', response);
      this.disabledManualPayment = false;
      if (response.Result.Result.length != 0) {
        this.manualPayflagUpdateResponse = response.Result.Result[0];
        this.manualPayflagUpdateResponse.enrollmentNo = enrollmentNo;
      }
    }, err => {
      console.log('Error while trying to check the manual Pay Flag');
      console.log('Error ::', err);
      this.disabledManualPayment = false;
    });
  }

  async manualPayFlagUpdate(M_S_SS_Flag, enrollmentNo) {
    let rowCount: any;
    await this.reviewService.manualPayFlagUpdate(M_S_SS_Flag, enrollmentNo).toPromise().then(async (response: any) => {
      console.log('Manual Pay Flag', response);
      rowCount = response.RowUpdated.rowCount;
      if (rowCount == 1) {

      }
      this.disabledManualPayment = false; // added to remove disable effect of CREATE ENROLLMENT Btn
    }, err => {
      console.log('Error while trying to check the manual Pay Flag update');
      console.log('Error ::', err);
      this.disabledManualPayment = false; // added to remove disable effect of CREATE ENROLLMENT Btn
    });
  }

  async closeModalFunction() {
    this.showSpinner = false;
    $("#isActiveManualPayModal").removeClass('hide_block');
    $("#isActiveManualPayModal").addClass('display_block');
    $("#isActiveManualPayModal").show("slow");
  }

  async makeInsertion(): Promise<void> {
    console.log('single insert');
    if (this.autoEnrollErrorMsg != '') {
      let months: any = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
      this.manualPaymentUser.PaymentMonth = this.manualPaymentUser.PaymentMonth + '';
      if (this.manualPaymentUser.PaymentMonth.length == 1) {
        this.manualPaymentUser.PaymentMonth = months[this.manualPaymentUser.PaymentMonth - 1];
      }
      this.manualPaymentUser.renewalTime = this.manualPaymentUser.PaymentMonth + '-' + this.manualPaymentUser.PaymentYr;

      await this.reviewService.firstTimePayment(this.manualPaymentUser, true).toPromise().then(async (response: any) => {
        console.log(response);
      });
    }
    await this.reviewService.addManualPayment(this.manualPaymentUser).toPromise()
      .then(async (response: any) => {
        console.log(response);
        this.isActiveProceed = false;
        this.isActiveLeaseProceed = false;
        this.Exception();
        // this.select(3);
        this.showSpinner = false;
        this.exportException = true;
        this.exportApproval = false;
        this.exportHold = false;
        this.paymentStatus = 'Program Exceptions';
        this.paymentException = true;
        this.paymentApproval = false;
        this.paymentHold = false;
        this.manualPayments = false;
        this.tableSec = true;
        this.spinId = '';
        console.log(this.manualPayUser);
        console.log(this.EXCEPTION_DATA);
        this.paid = true;
        this.successMsg = 'Manual payment(s) request has been created for ' +
          this.capitalize(this.manualPayUser.trim()) + ' for ' + this.monthsPayment + '.';
        this.manualPaymentSection = false;
        setTimeout(function () {
          this.paid = false;
        }.bind(this), 7000);
      });
  }

  async autoEnrollmentValidations() {
    console.log('array insert');
    this.makeManualPayment();
  }

  async getLFSPayCode() {
    let payHistory: any, payList: any;
    this.LFSErrorMsg = '';
    this.warnLFS = false;
    await this.reviewService.getLFSCode(this.manualPayUser).toPromise().then(async (response: any) => {
      console.log('LFSCode', response.LFSPAYYEAR.Result[0]);
      if (response.LFSPAYYEAR.Result != 0) {
        payHistory = response.LFSPAYYEAR.Result[0].paymentHistory;
        payList = response.LFSPAYYEAR.Result[1].paymentList;
        console.log('payHistory , payList ::', payHistory + ' ' + payList);

        if (payHistory != null && payList != null) {
          let maxYr = Math.max(payHistory, payList)
          this.LFSPayCodeRsponse = maxYr.toString();
          this.warnLFS = false;
        } else if (payHistory != null && payList == null) {
          this.LFSPayCodeRsponse = payHistory;
          this.warnLFS = false;
        } else if (payHistory == null && payList != null) {
          this.LFSPayCodeRsponse = payList;
          this.warnLFS = false;
        } else {
          // this.disabledManualPayment = true;
          this.validatingManualPay = true;
          this.LFSErrorMsg = 'No certification details found for ' + this.manualPaymentUser.firstName.trim() + ' ' + this.manualPaymentUser.lastName.trim();
          this.warnLFS = true;
        }
        if (!this.warnLFS) {
          this.addPaymentList[0].lfsPayYr = this.LFSPayCodeRsponse;
          this.addPaymentList[0].lfsPayNumber = this.newPay;
        }
        console.log('PayNumber', this.newPay, this.addPaymentList);
      }
      this.disabledManualPayment = false; // added to remove disable effect of CREATE ENROLLMENT Btn
    }, err => {
      console.log('Error while trying to get LFS Pay Code :: ' + err);
      this.disabledManualPayment = false; // added to remove disable effect of CREATE ENROLLMENT Btn
    });
  }

  async getLFSPayCodeforFirstPay() {
    let payYear: any, payCertLevel: any, paymentHistory: any, martizMaster: any;
    this.LFSFirstErrorMsg = '';
    this.warnFirstLFS = false;
    let dateForming = '01 ' + this.manualPaymentUser.PaymentMonth + ' ' + this.manualPaymentUser.PaymentYr;
    let dateIso = new Date(dateForming).toISOString().split('T')[0];
    this.manualPayUser.paymentDate = dateIso;
    console.log('this.manualPayUser', this.manualPayUser);
    await this.reviewService.getFirstLFSCode(this.manualPayUser).toPromise().then(async (response: any) => {
      console.log('LFSCode', response.Result.Result[0]);
      if (response.Result.Result != 0) {
        payYear = response.Result.Result[0].LFSPAYYEAR;
        payCertLevel = response.Result.Result[0].CertLevel;
        if (payYear != null && payYear != 0) {
          this.firstLFSPayCodeResponse = payYear;
          this.warnFirstLFS = false;
        } else {
          // this.disabledManualPayment = true;
          await this.reviewService.getCertYear(this.manualPayUser).toPromise().then(async (response: any) => {
            if (response.Result.Result != 0) {
              paymentHistory = response.Result.Result[0].PaymentHistory;
              martizMaster = response.Result.Result[0].MartizMaster;
              console.log('years ', paymentHistory, new Date().getFullYear(), (new Date().getFullYear()-2));
              if (paymentHistory >= (new Date().getFullYear()-2) ) {
                this.firstLFSPayCodeResponse = paymentHistory;
              } else {
                this.firstLFSPayCodeResponse = new Date().getFullYear();
              }
              // if (martizMaster > paymentHistory) {
                this.warnFirstLFS = false;
                payCertLevel = 'M';
              // }
              // else {
                this.noHistBtnDis = false;
              //   // this.disabledManualPayment = true;
                this.disabledManualPayment = false;
              //   this.validatingManualPay = true;
              //   this.LFSFirstErrorMsg = 'No certification details found for ' + this.manualPaymentUser.firstName.trim() + ' ' + this.manualPaymentUser.lastName.trim();
              //   this.warnFirstLFS = true;
              //   console.log('btndis', this.disabledManualPayment, ' nohist', this.noHist, ' lease', this.warnLease, ' 12', this.warn12,
              //     ' 24', this.warn24, ' max', this.warnMaximum, ' jobc', this.warnJobCode, ' cert', this.warnFirstLFS);
              //   if (this.warnLease || this.warn12 == 'true' || this.warn24 == 'true' || this.warnMaximum || this.warnJobCode || this.warnFirstLFS) {
              //     this.noHistBtnDis = true;
              //   }
              // }
            } else {
              this.noHistBtnDis = false;
              // this.disabledManualPayment = true;
              this.disabledManualPayment = false;
              this.validatingManualPay = true;
              this.LFSFirstErrorMsg = 'No certification details found for ' + this.manualPaymentUser.firstName.trim() + ' ' + this.manualPaymentUser.lastName.trim();
              this.warnFirstLFS = true;
              if (this.warnLease || this.warn12 == 'true' || this.warn24 == 'true' || this.warnMaximum || this.warnJobCode || this.warnFirstLFS) {
                this.noHistBtnDis = true;
              }
            }
          }, err => {
            console.log('Error while trying to get LFS Pay Code :: ' + err);
            this.disabledManualPayment = false;
          });

        }
        if (!this.warnFirstLFS) {
          this.noHistBtnDis = false;
          this.addPaymentList[0].lfsPayYr = this.firstLFSPayCodeResponse;
          this.addPaymentList[0].lfsPayNumber = '01';
          await this.manualPayFlag(this.manualPaymentUser.enrollmentNo, payCertLevel);
          if (this.warnLease || this.warn12 == 'true' || this.warn24 == 'true' || this.warnMaximum || this.warnJobCode || this.warnFirstLFS) {
            this.noHistBtnDis = true;
          }
        }
      }
    }, err => {
      console.log('Error while trying to get LFS Pay Code :: ' + err);
      this.disabledManualPayment = false;
    });
  }

  async makeManualPayment() {
    this.disabledManualPayment = true;
    if (this.manualPayflagUpdateResponse != '') {
      let autoEnrollEligible = this.manualPayflagUpdateResponse.AutoEnrollEligible;
      let flagUpdate = this.manualPayflagUpdateResponse.M_S_SS_Flag_Update;
      let enrollmentNo = this.manualPayflagUpdateResponse.enrollmentNo;
      if (autoEnrollEligible == 'Y') {
        await this.manualPayFlagUpdate(flagUpdate, enrollmentNo);
      }
    }
    if (this.autoEnrollErrorMsg != '') {
      let months: any = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
      this.manualPaymentUser.PaymentMonth = this.manualPaymentUser.PaymentMonth + '';
      if (this.manualPaymentUser.PaymentMonth.length == 1) {
        this.manualPaymentUser.PaymentMonth = months[this.manualPaymentUser.PaymentMonth - 1];
      }
      this.manualPaymentUser.renewalTime = this.manualPaymentUser.PaymentMonth + '-' + this.manualPaymentUser.PaymentYr;

      await this.reviewService.firstTimePayment(this.manualPaymentUser, true).toPromise().then(async (response: any) => {
        console.log('FirstPay ', response);
      });
    }
    for (let i = 0; i < this.addPaymentList.length; i++) {
      this.manualPaymentUser.remarks = this.addPaymentList[i].Remarks;
      this.manualPaymentUser.PaymentMonth = this.addPaymentList[i].PaymentMonth;
      this.manualPaymentUser.PaymentNumber = this.addPaymentList[i].PaymentNumber;
      this.manualPaymentUser.PaymentYr = this.addPaymentList[i].PaymentYr;
      this.manualPaymentUser.LFSPayYear = this.addPaymentList[i].PaymentYr;
      this.manualPaymentUser.LFSPayNo = this.addPaymentList[i].PaymentNumber;
      let payTimeProper = (this.manualPaymentUser.paymentDate).replace(/-/g, '\/');
      let enrolDtProper = (this.manualPaymentUser.enrolledDt).replace(/-/g, '\/');

      if (new Date(enrolDtProper) < new Date(new Date(payTimeProper).setFullYear(new Date(payTimeProper).getFullYear() - 2))) {
        this.manualPaymentUser.LFSPayYear = new Date().getFullYear();
        this.manualPaymentUser.LFSPayNo = 1;
        if (this.enteredPayNo !== null && this.enteredPayNo !== 0 && this.enteredPayNo !== undefined) {
          this.manualPaymentUser.LFSPayNo = this.enteredPayNo;
        } 
      }
      this.manualPaymentUser.associatename = this.manualPaymentUser.firstName.trim() + ' ' + this.manualPaymentUser.lastName.trim();
      this.reviewService.addManualPayment(this.manualPaymentUser).subscribe((response: any) => {
        this.isActiveProceed = false;
        this.isActiveLeaseProceed = false;
        if (i == this.addPaymentList.length - 1) {
          this.Exception();
          // this.select(3);
          this.showSpinner = false;
          this.exportException = true;
          this.exportApproval = false;
          this.exportHold = false;
          this.paymentStatus = 'Program Exceptions';
          this.paymentException = true;
          this.paymentApproval = false;
          this.paymentHold = false;
          this.manualPayments = false;
          this.tableSec = true;
          console.log('Manual Pay Users ', this.manualPayUser);
          console.log('Exception Data ', this.EXCEPTION_DATA);
          this.paid = true;
          this.closeMakePaymentModal();
          this.successMsg = 'Manual payment(s) request has been created for ' +
            this.capitalize(this.manualPayUser.NAME.trim()) + ' for ' + this.monthsPayment + '.';
          this.spinId = '';
          this.manualPaymentSection = false;
          setTimeout(function () {
            this.paid = false;
          }.bind(this), 7000);
        }
      });
    }
  }

  cancelIsActive() {
    $("#isActiveManualPayModal").removeClass('display_block');
    $("#isActiveManualPayModal").addClass('hide_block');
    $("#isActiveManualPayModal").show("slow");
  }

  proceedIsActive() {
    $("#isActiveManualPayModal").removeClass('display_block');
    $("#isActiveManualPayModal").addClass('hide_block');
    $("#isActiveManualPayModal").show("slow");
    this.isActiveProceed = true;
    this.showSpinner = true;
    this.activeLeaseValidation();
  }


  cancelIsActiveLease() {
    $("#isActiveLeaseModal").removeClass('display_block');
    $("#isActiveLeaseModal").addClass('hide_block');
    $("#isActiveLeaseModal").show("slow");
  }

  proceedIsActiveLease() {
    $("#isActiveLeaseModal").removeClass('display_block');
    $("#isActiveLeaseModal").addClass('hide_block');
    $("#isActiveLeaseModal").show("slow");
    this.isActiveLeaseProceed = true;
    this.showSpinner = true;
    if (this.addPaymentList.length == 1) {
      this.makeInsertion();
    } else {
      // this.autoEnrollmentValidations();
    }

  }

  capitalize(s) {
    if (s.trim() != '') {
      return s
        .toLowerCase()
        .split(' ')
        .map(function (word) {
          return word[0].toUpperCase() + word.substr(1);
        })
        .join(' ');
    }
  }

  //Export Excel
  exportFile() {
    if (this.exportApproval) {
      if (this.approvaldataSource.data.length != 0) {
        this.excelApproval = [];
        this.approvaldataSource.data.forEach((data) => {
          let obj = {
            'pay_id': data.pay_id,
            'dealer_code': data.dealercode,
            'spin_id': data.spinid,
            'associate_name': data.associatename,
            'Status': data.Status,
            'payment_number': data.paymentnumber,
            'LFS_PAY_CODE': data.LFSPAYCD,
            'payment_time': data.paymenttime,
            'Comments': data.Comments,
          }
          this.excelApproval.push(obj);
        });
        this.excelService.exportAsExcelFile(this.excelApproval, 'Payment Approval');
        this.exportApproval = false;
      } else {
        this.showDownloadErrorModal();
      }
    }
    else if (this.exportHold) {
      if (this.holdDataSource.data.length != 0) {
        this.excelHold = [];
        this.holdDataSource.data.forEach((data) => {
          let obj = {
            'pay_id': data.pay_id,
            'dealer_code': data.dealercode,
            'spin_id': data.spinid,
            'associate_name': data.associatename,
            'Status': data.Status,
            'payment_number': data.paymentnumber,
            'LFS_PAY_CODE': data.LFSPAYCD,
            'payment_time': data.paymenttime,
            'Comments': data.Comments,
          }
          this.excelHold.push(obj);
        });
        this.excelService.exportAsExcelFile(this.excelHold, 'Payment Hold');
        this.exportHold = false;
      } else {
        this.showDownloadErrorModal();
      }
    }
    else if (this.exportException) {
      if (this.exceptionDataSource.data.length != 0) {
        this.excelException = [];
        this.exceptionDataSource.data.forEach((data) => {
          let obj = {
            'pay_id': data.pay_id,
            'dealer_code': data.dealercode,
            'spin_id': data.spinid,
            'associate_name': data.associatename,
            'Status': data.Status,
            'payment_number': data.paymentnumber,
            'LFS_PAY_CODE': data.LFSPAYCD,
            'payment_time': data.paymenttime,
            'Comments': data.Comments,
          }
          this.excelException.push(obj);
        });
        this.excelService.exportAsExcelFile(this.excelException, 'Payment Exception');
        this.exportException = false;
      } else {
        this.showDownloadErrorModal();
      }
    }
    else {
      if (this.totalCheckedArrayforApproval.length != 0) {
        let excelApprove = [];
        this.totalCheckedArrayforApproval.forEach((data) => {
          let obj = {
            'DEALER_CODE': data.DEALER_CODE,
            'SPIN_ID': data.SPIN_ID,
            'ASSOCIATE_NAME': data.associatename,
            'PAYMENT_NUMBER': data.paymentnumber,
            'PAYMENT_TIME': data.paymenttime,
            'AMOUNT': data.newAmount,
            'COMMENTS': data.comments,
          }
          excelApprove.push(obj);
        });
        this.excelService.exportAsExcelFile(excelApprove, 'Payment Details');
      } else {
        this.showDownloadErrorModal();
      }
    }
  }

  showDownloadErrorModal() {
    $("#errorExcelModal").removeClass('hide_block');
    $("#errorExcelModal").addClass('display_block');
    $("#errorExcelModal").show("slow");
  }

  closeDownloadErrorModal() {
    $("#errorExcelModal").removeClass('display_block');
    $("#errorExcelModal").addClass('hide_block');
    $("#errorExcelModal").show("slow");
  }

  //Save Checked Columns
  async saveSelectedColumns() {
    for (let i = 0; i < this.checkedArray.length; i++) {
      await this.reviewService.approvePayments(this.checkedArray[i]).toPromise().then(async (response: any) => {
        console.log('SAve Checked Colms', response);
      });
    }
    for (let i = 0; i < this.selection.length; i++) {
      this.checkedRow.push(this.selection[i]);
    }
    this.appService.setCheckedRow(this.checkedRow);
    this.totalCheckedSaved = 0;
  }

  async saveSelectedColumnsApproval() {
    let uncheckPayId = '', checkPayId = '', checkArr = [], uncheckArr = [];
    for (let i = 0; i < this.approvaldataSource.data.length; i++) {
      if (this.approvaldataSource.data[i].toBeSaved) {
        if (this.approvaldataSource.data[i].check) {
          checkPayId += this.approvaldataSource.data[i].pay_id + ', ';
          checkArr.push(this.approvaldataSource.data[i])
        } else {
          uncheckPayId += this.approvaldataSource.data[i].pay_id + ', ';
          uncheckArr.push(this.approvaldataSource.data[i])
        }
      }
    }
    console.log('Checked :: ' + checkArr.length + '\n' + 'Unchecked :: ' + uncheckArr.length);
    if (checkArr.length + uncheckArr.length != 0) {
      if ((checkArr.length + uncheckArr.length) > 30) {
        if (checkPayId != '') {
          console.log('payIds ', checkPayId.slice(0, checkPayId.length - 2));
          checkPayId = checkPayId.slice(0, checkPayId.length - 2);
          await this.reviewService.bulkApprovePayments(checkPayId).toPromise().then(async (bulkResponse: any) => {
            console.log('Bulk Save Response ', bulkResponse.RowUpdated.rowCount);
            this.appService.unsavedChanges = false;
            this.paid = true;
            this.successMsg = 'Changes have been saved successfully!';
            $('html,body').animate({ scrollTop: 0 }, "smooth");
            setTimeout(function () {
              this.paid = false;
            }.bind(this), 7000);
          }, err => {
            console.log('Error in  trying to bulk Save Payments');
          });
        }
        if (uncheckPayId != '') {
          console.log('uncheckPayId ', uncheckPayId.slice(0, uncheckPayId.length - 2));
          uncheckPayId = uncheckPayId.slice(0, uncheckPayId.length - 2);
          await this.reviewService.bulkSaveStatusUnCheck(uncheckPayId).toPromise().then(async (uncheckBulkResponse: any) => {
            console.log('Uncheck Bulk Save Response ', uncheckBulkResponse.RowUpdated.rowCount);
            this.appService.unsavedChanges = false;
            this.paid = true;
            this.successMsg = 'Changes have been saved successfully!';
            $('html,body').animate({ scrollTop: 0 }, "smooth");
            setTimeout(function () {
              this.paid = false;
            }.bind(this), 7000);
          }, err => {
            console.log('Error in  trying to bulk Save Payments');
          });
        }
      } else {
        if (checkArr.length != 0) {
          for (let i = 0; i < checkArr.length; i++) {
            await this.reviewService.savePayments(checkArr[i]).toPromise().then(async (response: any) => {
              this.appService.unsavedChanges = false;
              console.log('Save Regular Pay', response);
              this.paid = true;
              this.successMsg = 'Changes have been saved successfully!';
              $('html,body').animate({ scrollTop: 0 }, "smooth");
              setTimeout(function () {
                this.paid = false;
              }.bind(this), 7000);
            });
          }
        }
        if (uncheckArr.length != 0) {
          for (let i = 0; i < uncheckArr.length; i++) {
            await this.reviewService.savePayments(uncheckArr[i]).toPromise().then(async (response: any) => {
              this.appService.unsavedChanges = false;
              console.log('Save Regular Pay', response);
              this.paid = true;
              this.successMsg = 'Changes have been saved successfully!';
              $('html,body').animate({ scrollTop: 0 }, "smooth");
              setTimeout(function () {
                this.paid = false;
              }.bind(this), 7000);
            });
          }
        }
      }
    }
    for (let i = 0; i < this.selection.length; i++) {
      this.checkedRow.push(this.selection[i]);
    }
    this.appService.setCheckedRow(this.checkedRow);
    this.totalCheckedSaved = 0;
  }

  async saveSelectedColumnHold() {
    let uncheckPayId = '', checkPayId = '', checkArr = [], uncheckArr = [];
    for (let i = 0; i < this.holdDataSource.data.length; i++) {
      if (this.holdDataSource.data[i].toBeSaved) {
        if (this.holdDataSource.data[i].check) {
          checkPayId += this.holdDataSource.data[i].pay_id + ', ';
          checkArr.push(this.holdDataSource.data[i])
        } else {
          uncheckPayId += this.holdDataSource.data[i].pay_id + ', ';
          uncheckArr.push(this.holdDataSource.data[i])
        }
      }
    }
    console.log('Checked :: ' + checkArr.length + '\n' + 'Unchecked :: ' + uncheckArr.length);
    if (checkArr.length + uncheckArr.length != 0) {
      if ((checkArr.length + uncheckArr.length) > 30) {
        if (checkPayId != '') {
          console.log('payIds ', checkPayId.slice(0, checkPayId.length - 2));
          checkPayId = checkPayId.slice(0, checkPayId.length - 2);
          await this.reviewService.bulkApprovePayments(checkPayId).toPromise().then(async (bulkResponse: any) => {
            console.log('Check Bulk Save Response ', bulkResponse.RowUpdated.rowCount);
            this.appService.unsavedChanges = false;
            console.log('Override', this.overrideHoldClicked);
            this.paid = true;
            $('html,body').animate({ scrollTop: 0 }, "smooth");
            setTimeout(function () {
              this.paid = false;
            }.bind(this), 7000);
            setTimeout(function () {
              $("#overrideHoldModal").removeClass('display_block');
              $("#overrideHoldModal").addClass('hide_block');
              $("#overrideHoldModal").show("slow");
              if (this.overrideHoldClicked) {
                this.successMsg = 'Override Hold for the payment has been saved successfully!';
                this.overrideHoldClicked = false;
              }
              if (!this.overrideHoldClicked) {
                this.successMsg = 'Changes have been saved successfully!';
              }
            }.bind(this), 30);
          }, err => {
            console.log('Error in  trying to bulk Save Payments');
          });
        }
        if (uncheckPayId != '') {
          console.log('uncheckPayId ', uncheckPayId.slice(0, uncheckPayId.length - 2));
          uncheckPayId = uncheckPayId.slice(0, uncheckPayId.length - 2);
          await this.reviewService.bulkSaveStatusUnCheck(uncheckPayId).toPromise().then(async (uncheckBulkResponse: any) => {
            console.log('Uncheck Bulk Save Response ', uncheckBulkResponse.RowUpdated.rowCount);
            this.appService.unsavedChanges = false;
            console.log('Override', this.overrideHoldClicked);
            this.paid = true;
            $('html,body').animate({ scrollTop: 0 }, "smooth");
            setTimeout(function () {
              this.paid = false;
            }.bind(this), 7000);
            setTimeout(function () {
              $("#overrideHoldModal").removeClass('display_block');
              $("#overrideHoldModal").addClass('hide_block');
              $("#overrideHoldModal").show("slow");
              if (this.overrideHoldClicked) {
                this.successMsg = 'Override Hold for the payment has been saved successfully!';
                this.overrideHoldClicked = false;
              }
              if (!this.overrideHoldClicked) {
                this.successMsg = 'Changes have been saved successfully!';
              }
            }.bind(this), 30);
          }, err => {
            console.log('Error in  trying to bulk Save Payments');
          });
        }
      } else {
        if (checkArr.length != 0) {
          for (let i = 0; i < checkArr.length; i++) {
            await this.reviewService.savePayments(checkArr[i]).toPromise().then(async (response: any) => {
              console.log('SaveChecked ', response);
              this.appService.unsavedChanges = false;
              console.log('Override', this.overrideHoldClicked);
              this.paid = true;
              $('html,body').animate({ scrollTop: 0 }, "smooth");
              setTimeout(function () {
                this.paid = false;
              }.bind(this), 7000);
              setTimeout(function () {
                $("#overrideHoldModal").removeClass('display_block');
                $("#overrideHoldModal").addClass('hide_block');
                $("#overrideHoldModal").show("slow");
                if (this.overrideHoldClicked) {
                  this.successMsg = 'Override Hold for the payment has been saved successfully!';
                  this.overrideHoldClicked = false;
                }
                if (!this.overrideHoldClicked) {
                  this.successMsg = 'Changes have been saved successfully!';
                }
              }.bind(this), 30);
            });
          }
        }
        if (uncheckArr.length != 0) {
          for (let i = 0; i < uncheckArr.length; i++) {
            await this.reviewService.savePayments(uncheckArr[i]).toPromise().then(async (response: any) => {
              console.log('SaveUncheck ', response);
              this.appService.unsavedChanges = false;
              console.log('Override', this.overrideHoldClicked);
              this.paid = true;
              $('html,body').animate({ scrollTop: 0 }, "smooth");
              setTimeout(function () {
                this.paid = false;
              }.bind(this), 7000);
              setTimeout(function () {
                $("#overrideHoldModal").removeClass('display_block');
                $("#overrideHoldModal").addClass('hide_block');
                $("#overrideHoldModal").show("slow");
                if (this.overrideHoldClicked) {
                  this.successMsg = 'Override Hold for the payment has been saved successfully!';
                  this.overrideHoldClicked = false;
                }
                if (!this.overrideHoldClicked) {
                  this.successMsg = 'Changes have been saved successfully!';
                }
              }.bind(this), 30);
            });
          }
        }
      }
    }

    for (let i = 0; i < this.selection.length; i++) {
      this.checkedRow.push(this.selection[i]);
    }
    this.appService.setCheckedRow(this.checkedRow);
    this.totalCheckedSaved = 0;
  }

  async saveSelectedColumnException() {
    let uncheckPayId = '', checkPayId = '', checkArr = [], uncheckArr = [];
    for (let i = 0; i < this.exceptionDataSource.data.length; i++) {
      if (this.exceptionDataSource.data[i].toBeSaved) {
        if (this.exceptionDataSource.data[i].check) {
          checkPayId += this.exceptionDataSource.data[i].pay_id + ', ';
          checkArr.push(this.exceptionDataSource.data[i])
        } else {
          uncheckPayId += this.exceptionDataSource.data[i].pay_id + ', ';
          uncheckArr.push(this.exceptionDataSource.data[i])
        }
      }
    }
    console.log('Checked :: ' + checkArr.length + '\n' + 'Unchecked :: ' + uncheckArr.length);
    if (checkArr.length + uncheckArr.length != 0) {
      if ((checkArr.length + uncheckArr.length) > 30) {
        if (checkPayId != '') {
          console.log('payIds ', checkPayId.slice(0, checkPayId.length - 2));
          checkPayId = checkPayId.slice(0, checkPayId.length - 2);
          await this.reviewService.bulkApprovePayments(checkPayId).toPromise().then(async (bulkResponse: any) => {
            console.log('Check Bulk Save Response ', bulkResponse.RowUpdated.rowCount);
            this.appService.unsavedChanges = false;
            this.paid = true;
            this.successMsg = 'Changes have been saved successfully!';
            $('html,body').animate({ scrollTop: 0 }, "smooth");
            setTimeout(function () {
              this.paid = false;
            }.bind(this), 7000);
          }, err => {
            console.log('Error in  trying to bulk Save Payments');
          });
        }
        if (uncheckPayId != '') {
          console.log('uncheckPayId ', uncheckPayId.slice(0, uncheckPayId.length - 2));
          uncheckPayId = uncheckPayId.slice(0, uncheckPayId.length - 2);
          await this.reviewService.bulkSaveStatusUnCheck(uncheckPayId).toPromise().then(async (uncheckBulkResponse: any) => {
            console.log('Uncheck Bulk Save Response ', uncheckBulkResponse.RowUpdated.rowCount);
            this.appService.unsavedChanges = false;
            this.paid = true;
            this.successMsg = 'Changes have been saved successfully!';
            $('html,body').animate({ scrollTop: 0 }, "smooth");
            setTimeout(function () {
              this.paid = false;
            }.bind(this), 7000);
          }, err => {
            console.log('Error in  trying to bulk Save Payments');
          });
        }
      } else {
        if (checkArr.length != 0) {
          for (let i = 0; i < checkArr.length; i++) {
            await this.reviewService.savePayments(checkArr[i]).toPromise().then(async (response: any) => {
              this.appService.unsavedChanges = false;
              console.log('SaveChecked ', response);
              this.paid = true;
              this.successMsg = 'Changes have been saved successfully!';
              $('html,body').animate({ scrollTop: 0 }, "smooth");
              setTimeout(function () {
                this.paid = false;
              }.bind(this), 7000);
            });
          }
        }
        if (uncheckArr.length != 0) {
          for (let i = 0; i < uncheckArr.length; i++) {
            await this.reviewService.savePayments(uncheckArr[i]).toPromise().then(async (response: any) => {
              this.appService.unsavedChanges = false;
              console.log('SaveUncheck ', response);
              this.paid = true;
              this.successMsg = 'Changes have been saved successfully!';
              $('html,body').animate({ scrollTop: 0 }, "smooth");
              setTimeout(function () {
                this.paid = false;
              }.bind(this), 7000);
            });
          }
        }
      }
    }
    for (let i = 0; i < this.selection.length; i++) {
      this.checkedRow.push(this.selection[i]);
    }
    this.appService.setCheckedRow(this.checkedRow);
    this.totalCheckedSaved = 0;
  }

  autoSaveCommentsApproval(row) {
    this.reviewService.savePayments(row).subscribe((response: any) => {
      // this.appService.unsavedChanges = false;
      console.log('AutoSave', response);
    }, err => console.log('Error in autoSave', err));
  }

  closeDealerDetails() {
    this.holdDataSource.data[this.selectedHoldIndex].check = false;
    this.holdDataSource.data[this.selectedHoldIndex].Status = 3;
    this.holdDataSource.data[this.selectedHoldIndex].toBeSaved = false;
    setTimeout(
      function () {
        $("#overrideHoldModal").removeClass('display_block');
        $("#overrideHoldModal").addClass('hide_block');
        $("#overrideHoldModal").hide("slow");
        this.getAllPaymentFn();
      }.bind(this),
      100
    );
  }

  overrideHoldFn() {
    this.dataForOverrideHold[0] = this.holdDataSource.data[this.selectedHoldIndex];
    this.reviewService.approvePayments(this.dataForOverrideHold[0]).subscribe((response: any) => {
      console.log('Override Hold fn', response);
      this.holdDataSource.data[this.selectedHoldIndex].check = true;
      this.holdDataSource.data[this.selectedHoldIndex].toBeSaved = true;
      // this.holdDataSource.data[this.selectedHoldIndex].paymentnumber = 
      // this.holdDataSource.data[this.selectedHoldIndex].
      this.holdDataSource.data[this.selectedHoldIndex].Status = '3';
      setTimeout(
        function () {
          this.holdCheckCount = $("#holdCheckbox:checked").length;
          this.totalChecked = 0;
          this.totalChecked = this.holdCheckCount + this.approvalCheckCount + this.ExceptionCheckCount;
          if (this.totalChecked != 0) {
            $("#approveModalId").attr('data-badge', this.totalChecked + '');
          } else {
            $("#approveModalId").removeAttr('data-badge');
          }
          $("#overrideHoldModal").removeClass('display_block');
          $("#overrideHoldModal").addClass('hide_block');
          $("#overrideHoldModal").hide("slow");
        }.bind(this),
        100
      );
    });
  }

  @HostListener('document:click', ['$event'])
  clickout(event: MouseEvent) {
    const heading = (<HTMLInputElement>event.srcElement).className;
    this.appService.setThisRequestPayment(this);
  }

  onNext() {
    $('html,body').animate({ scrollTop: 0 }, "smooth");
  }
}
